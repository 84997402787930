export * from 'Api/Contracts/Dtos/Drive/ISymlinkInputDto';
export * from 'Api/Contracts/Dtos/GeoRouting';

import { JsonObject, JsonSchemaDto } from 'Api/Contracts/Dtos/SmartObject';
import { CreativeCommonsLicense } from 'Api/Dto/CreativeCommonsLicense';
import { File as DriveFile, IFileShare, WorkerTaskState } from 'Api/Dto/Drive';
import { CoordinateSystem, ICoordinates } from 'Api/Dto/Project';
import { IIdentity } from 'Api/Dto/Shares';
import { ITooltipStyle, RenderType } from 'Framework/Components/Controls/XView/Core';
import { GeoJSON, MultiPoint, Point } from 'geojson';

export enum ManipulationState {
    None,
    Copy,
    Cut
}

export enum SortOptions {
    name_asc,
    name_desc,
    date_asc,
    date_desc,
    connection_asc,
    connection_desc,
    status_asc,
    status_desc,
    url_asc,
    url_desc
}

export interface IUpdateProjectItemViewModel {
    projectId: number;
    itemId: number;
    properties: { [key: string]: any };
}

export class EventAction {
    public constructor(
        actionName: string,
        name: string,
        description: string, callToAction: string, regex: RegExp, linkWithAssetType: Array<any> = null) {
        this.actionName = actionName;
        this.name = name;
        this.description = description;
        this.callToAction = callToAction;
        this.regex = regex;
        this.linkWithAssetType = linkWithAssetType;
    }

    public parseAction(action: string, assetItem?: AssetItem): boolean {
        return false;
    }

    public toString(assetItem?: AssetItem): string {
        return '';
    }

    public isValid(): boolean {
        return false;
    }

    public actionName: string;
    public name: string;
    public description: string;
    public callToAction: string;
    public regex: RegExp;
    public linkWithAssetType: Array<any>;

    public static hotspotAvailableEvents: Array<{ key: string, eventName: string }> = [
        { key: 'Clic', eventName: 'onClick' },
        //'Double clic',
        //'Mouse Enter',
        //'Mouse Leave',
        //'Enter in frustum',
        //'Leave frustum',

        //'Double clic',
        //'Drag enter',
        //'Drag leave',
        //'Drag over',
        //'Drop',
        //'Key Down',
        //'Key Up',
        //'Loaded',
        //'Mouse Down',
        //'Clic Gauche Down',
        //'Clic Gauche Up',
        //'Clic Droit Down',
        //'Clic Droit Up',
        //'Expanded'
    ];

    public static projectAvailableEvents: Array<{ key: string, eventName: string }> = [
        { key: 'Loaded', eventName: 'onLoaded' },
    ];

    public static panoramaGroupAvailableEvents: Array<{ key: string, eventName: string }> = [
        { key: 'Loaded', eventName: 'onLoaded' },
    ];

    public static panoramaVersionAvailableEvents: Array<{ key: string, eventName: string }> = [
        { key: 'Loaded', eventName: 'onLoaded' },
    ];
}

export class EventActionGroup extends EventAction {

    public constructor(actionName: string,
        name: string,
        actions: Array<EventAction>) {
        super(actionName, name, null, null, null);
        this.eventActions = actions;
    }

    public eventActions: Array<EventAction>;
}

export enum AssetItemType {
    Normal = 0,
    Directory = 1,
    Project = 2,
    File = 4,
    Link = 8,
    Hotspot = 16,
    PanoramaVersion = 32,
    ProjectMap = 64,
    FileMap = 128,
    StreetMap = 256,
    PanoramaItemLink = 512,
    Navigation = 1024,
    MapArea = 2048,
    Template = 4096,
    PanoramaGroup = 8192,
    SmartObject = 16384,
}

export enum FormType {
    Rectangle = 1, Circle = 2, Polygon = 3
}
export enum NavigationType {
    None = 0, List = 1, Map = 2
}
export enum MapSize {
    Hidden = 1, Small = 2, Medium = 4, Large = 8
}
export enum StreetMapType {
    GoogleMaps = 1, OpenStreetMap = 2, BingMap = 4
}

export enum SearchOption {
    TopDirectoryOnly,
    AllDirectories
}

export interface IIconRenderer {
    type: string,
    icon: string,
    color: string,
    size: string,
    tooltip: string,
    tooltipStyle?: ITooltipStyle
}

export interface IShapeRenderer {
    type: string,
    color: string,
    tooltip: string,
    formType: FormType,
    hoverColor: string,
    selectedColor: string,
    stroke: { color: string, width: number },
    tooltipStyle?: ITooltipStyle
}

export interface ITextRenderer {
    type: 'textRenderer';
    text: string;
    color: string;
    size: number;
    tooltip: string;
    tooltipStyle?: ITooltipStyle;
}

export type IRenderer = IIconRenderer | IShapeRenderer | ITextRenderer;

export interface IPanoramaLocation {
    worldCoordinates?: Point;
    localCoordinates?: Point;
}

export class RendererHelper {
    public static isIconRenderer(renderer: IRenderer): renderer is IIconRenderer {
        return renderer
            && (renderer as IIconRenderer).type !== undefined
            && (renderer as IIconRenderer).type === 'iconRenderer';
    }

    public static isShapeRenderer(renderer: IRenderer): renderer is IShapeRenderer {
        return renderer
            && (renderer as IIconRenderer).type !== undefined
            && (renderer as IIconRenderer).type === 'shapeRenderer';
    }

    public static isTextRenderer(renderer: IRenderer): renderer is ITextRenderer {
        return (renderer as ITextRenderer).type === 'textRenderer';
    }
}

export interface IOrderedAssetItem {
    order: number;
}

export class AssetItem {
    public constructor(type: AssetItemType = AssetItemType.Normal) {
        this.assetItemId = 0;
        this.name = null;
        this.parentId = null;
        this.type = type;
    }

    public static fromJson(json: any): AssetItem {
        let assetItem: AssetItem = new AssetItem();
        assetItem.assetItemId = json.assetItemId;
        assetItem.name = json.name;
        assetItem.parentId = json.parentId;
        assetItem.type = json.type;
        assetItem.events = json.events || {};

        return assetItem;
    }

    public static jsonToArray<T>(value: any): Array<T> {
        if (Array.isArray(value)) {
            let array = new Array<T>();
            for (let v of value) {
                array.push(AssetItem.jsonToObject<T>(v));
            }
            return array;
        }
        return [];
    }

    public static jsonToObject<T>(value: any): T {
        if (value && value.type) {
            switch (value.type) {
                case AssetItemType.StreetMap:
                    return StreetMap.fromJson(value) as unknown as T;
                case AssetItemType.PanoramaItemLink:
                    return PanoramaItemLink.fromJson(value) as unknown as T;
                case AssetItemType.FileMap:
                    return FileMap.fromJson(value) as unknown as T;
                case AssetItemType.Navigation:
                    return Navigation.fromJson(value) as unknown as T;
                case AssetItemType.PanoramaVersion:
                    return AssetPanoramaVersion.fromJson(value) as unknown as T;
                case AssetItemType.Project:
                    return AssetProject.fromJson(value) as unknown as T;
                case AssetItemType.Template:
                    return AssetTemplate.fromJson(value) as unknown as T;
                case AssetItemType.Link:
                    return AssetLink.fromJson(value) as unknown as T;
                case AssetItemType.Hotspot:
                    return AssetHotspot.fromJson(value) as unknown as T;
                case AssetItemType.MapArea:
                    return AssetMapArea.fromJson(value) as unknown as T;
                case AssetItemType.File:
                    return AssetFile.fromJson(value) as unknown as T;
                case AssetItemType.Directory:
                    return AssetDirectory.fromJson(value) as unknown as T;
                case AssetItemType.Normal:
                    return AssetFile.fromJson(value) as unknown as T;
                case AssetItemType.ProjectMap:
                    return ProjectMap.fromJson(value) as unknown as T;
                case AssetItemType.PanoramaGroup:
                    return AssetPanoramaGroup.fromJson(value) as unknown as T;
                case AssetItemType.SmartObject:
                    return SmartObjectDto.fromJson(value) as unknown as T;
                default:
                    break;
            }
        }
        return value;
    }

    public get isSystem(): boolean {
        return this._reservedNames.includes(this.name);
    }

    private readonly _reservedNames: Array<string> = [
        AssetDirectory.DefaultSmartObjectsDirectory
    ];

    public assetItemId: number;
    public name: string;
    public parentId?: number;
    public parent?: AssetDirectory;
    public type: AssetItemType;
    public events: { [key: string]: string };
}

export class AssetFile extends AssetItem {
    public constructor(type: AssetItemType = AssetItemType.File) {
        super(type);
    }

    public static fromJson(json: any): AssetFile {
        let assetFile: AssetFile = Object.assign(new AssetFile(), AssetItem.fromJson(json));
        assetFile.file = json.file;

        if (assetFile.file) {
            assetFile.file.state = {
                creationTimeUtc: new Date(),
                description: null,
                filePathGuid: assetFile.file.guid,
                taskId: 0,
                resultId: 0,
                state: WorkerTaskState.Created,
                typeOfBody: null,
                typeOfHeader: null,
                lastExecutedTimeUtc: null
            };
        }

        return assetFile;
    }

    public file: DriveFile;
}

export class AssetDirectory extends AssetFile {
    public constructor(type: AssetItemType = AssetItemType.Directory) {
        super(type);
    }

    public static fromJson(json: any): AssetDirectory {
        let assetDirectory: AssetDirectory = Object.assign(new AssetDirectory(), AssetFile.fromJson(json));
        assetDirectory.children = AssetItem.jsonToArray<AssetItem>(json.children);

        assetDirectory.children.forEach(c => {
            c.parent = assetDirectory;
        });

        return assetDirectory;
    }

    public addChildren(...children: Array<AssetItem>): void {
        this.children.push(...children);
    }

    public removeChildren(...children: Array<AssetItem>): void {
        this.children = this.children.filter(c => !children.includes(c));
    }

    public get isDefaultSmartObjectDirectory(): boolean {
        return this.name == AssetDirectory.DefaultSmartObjectsDirectory;
    }

    public static readonly DefaultSmartObjectsDirectory: string = '$AssetItems.Project.SmartObjects';

    public children: Array<AssetItem>;
}

export interface ICompassConfiguration {
    background: string,
    foreground: string,
    border: string,
    borderThickness: number,
    arcBackground: string,
    arcBorder: string,
    arcBorderThickness: number,
    baseArcBackground: string,
    baseArcBorder: string,
    baseArcBorderThickness: number
}

export class Navigation extends AssetDirectory {
    public constructor() {
        super(AssetItemType.Navigation);
    }

    public static fromJson(json: any): Navigation {
        let assetNavigation: Navigation = Object.assign(new Navigation(), AssetDirectory.fromJson(json));
        assetNavigation.navigationType = json.navigationType;
        assetNavigation.compassConfiguration = json.compassConfiguration;

        if (assetNavigation.navigationType == NavigationType.List) {
            assetNavigation.children.sort((a: PanoramaItemLink, b: PanoramaItemLink) => a.order - b.order);
        }

        assetNavigation.children.forEach(c => {
            c.parent = assetNavigation;
        });

        return assetNavigation;
    }

    public navigationType: NavigationType;
    public compassConfiguration: ICompassConfiguration;
}

export class ProjectMap extends AssetDirectory {
    public constructor(type: AssetItemType = AssetItemType.ProjectMap) {
        super(type);
    }

    public static fromJson(json: any): ProjectMap {
        let assetProjectMap: ProjectMap = Object.assign(new ProjectMap(), AssetDirectory.fromJson(json));

        assetProjectMap.children.forEach(c => {
            c.parent = assetProjectMap;
        });

        return assetProjectMap;
    }
}

export interface IMapLocation {
    itemCoordinates: MultiPoint;
    worldCoordinates?: MultiPoint;
    localCoordinates?: MultiPoint;
}

export class FileMap extends ProjectMap {
    public constructor() {
        super(AssetItemType.FileMap);
    }

    public static fromJson(json: any): FileMap {
        let assetFileMap: FileMap = Object.assign(new FileMap(), ProjectMap.fromJson(json));
        assetFileMap.isDefault = json.isDefault;
        assetFileMap.location = json.location;

        assetFileMap.children.forEach(c => {
            c.parent = assetFileMap;
        });

        return assetFileMap;
    }

    public isDefault: boolean;
    public location: IMapLocation;
}

export class StreetMap extends ProjectMap {
    public constructor() {
        super(AssetItemType.StreetMap);
    }

    public static fromJson(json: any): StreetMap {
        let assetStreetMap: StreetMap = Object.assign(new StreetMap(), ProjectMap.fromJson(json));
        assetStreetMap.coordinates = json.coordinates;
        assetStreetMap.provider = json.provider;

        assetStreetMap.children.forEach(c => {
            c.parent = assetStreetMap;
        });

        return assetStreetMap;
    }

    public coordinates: string;
    public provider: StreetMapType;
}

export class AssetProject extends AssetDirectory {
    public constructor(type: AssetItemType = AssetItemType.Project) {
        super(type);
    }

    public static fromJson(json: any): AssetProject {
        let assetLink: AssetProject = Object.assign(new AssetProject(), AssetDirectory.fromJson(json));
        assetLink.projectId = json.projectId;
        assetLink.fileShares = AssetItem.jsonToArray<IFileShare>(json.fileShares);

        assetLink.children.forEach(c => {
            c.parent = assetLink;
        });

        return assetLink;
    }

    public projectId: number;
    public project: Project;
    public fileShares: Array<IFileShare>;
}

export class AssetTemplate extends AssetProject {
    public constructor() {
        super(AssetItemType.Template);
    }

    public static fromJson(json: any): AssetTemplate {
        let assetTemplate: AssetTemplate = Object.assign(new AssetTemplate(), AssetProject.fromJson(json));
        assetTemplate.description = json.description || null;
        assetTemplate.project = AssetItem.jsonToObject<Project>(json.project);

        assetTemplate.children.forEach(c => {
            c.parent = assetTemplate;
        });

        return assetTemplate;
    }

    public description: string;
}

export class AssetLink extends AssetItem {
    public constructor(type: AssetItemType = AssetItemType.Link) {
        super(type);
    }

    public static fromJson(json: any): AssetLink {
        let assetLink: AssetLink = Object.assign(new AssetLink(), AssetItem.fromJson(json));
        assetLink.assetItemLinkedId = json.assetItemLinkedId ?? null;

        return assetLink;
    }

    public assetItemLinkedId?: number;
}

export class PanoramaItemLink extends AssetLink implements IOrderedAssetItem {
    public constructor() {
        super(AssetItemType.PanoramaItemLink);
    }

    public static fromJson(json: any): PanoramaItemLink {
        let assetPanoramaItemLink: PanoramaItemLink = Object.assign(new PanoramaItemLink(), AssetLink.fromJson(json));
        assetPanoramaItemLink.order = json.order;

        return assetPanoramaItemLink;
    }

    public static fromAssetPanorama(assetPanorama: AssetPanoramaGroup, parentId: number, order: number): PanoramaItemLink {
        let link = new PanoramaItemLink();
        link.name = assetPanorama.name;
        link.parentId = parentId;
        link.assetItemLinkedId = assetPanorama.assetItemId;
        link.order = order;

        return link;
    }

    public order: number;
}

export class AssetHotspot extends AssetLink {
    public constructor(type: AssetItemType = AssetItemType.Hotspot) {
        super(type);
    }

    public static fromJson(json: any): AssetHotspot {
        let assetHotspot: AssetHotspot = Object.assign(new AssetHotspot(), AssetLink.fromJson(json));
        assetHotspot.coordinateSystem = json.coordinateSystem;
        assetHotspot.coordinates = json.coordinates;
        assetHotspot.content = json.content;

        return assetHotspot;
    }

    public coordinateSystem: CoordinateSystem;
    public coordinates: Array<ICoordinates>;
    public content: IRenderer;
}

export class AssetMapArea extends AssetHotspot {
    public constructor() {
        super(AssetItemType.MapArea);
    }

    public static fromJson(json: any): AssetMapArea {
        let assetAssetMapArea: AssetMapArea = Object.assign(new AssetMapArea(), AssetHotspot.fromJson(json));
        assetAssetMapArea.areaCoordinates = JSON.stringify(json.areaCoordinates);

        return assetAssetMapArea;
    }

    public areaCoordinates: string;
    declare public content: IShapeRenderer;
}

export class AssetPanoramaVersion extends AssetDirectory implements IOrderedAssetItem {
    public constructor() {
        super(AssetItemType.PanoramaVersion);
    }

    public static fromJson(json: any): AssetPanoramaVersion {
        let assetAssetPanorama: AssetPanoramaVersion = Object.assign(new AssetPanoramaVersion(), AssetDirectory.fromJson(json));
        assetAssetPanorama.renderType = json.renderType;
        assetAssetPanorama.startOrientation = json.startOrientation;
        assetAssetPanorama.northOrientation = json.northOrientation;
        assetAssetPanorama.offset = json.offset;
        assetAssetPanorama.order = json.order;
        assetAssetPanorama.location = json.location;

        assetAssetPanorama.children.forEach(c => {
            c.parent = assetAssetPanorama;
        });

        return assetAssetPanorama;
    }

    public renderType: RenderType;
    public startOrientation: ICoordinates;
    public northOrientation: number;
    public offset: number;
    public order: number;
    public location: IPanoramaLocation;
}

export class AssetPanoramaGroup extends AssetDirectory {
    public constructor() {
        super(AssetItemType.PanoramaGroup);
    }

    static fromJson(json: any): AssetPanoramaGroup {
        let assetAssetPanoramaGroup: AssetPanoramaGroup = Object.assign(new AssetPanoramaGroup(), AssetDirectory.fromJson(json));
        assetAssetPanoramaGroup.defaultVersionId = json.defaultVersionId;
        assetAssetPanoramaGroup.hotspots = AssetItem.jsonToArray<AssetHotspot>(json.hotspots);

        assetAssetPanoramaGroup.children.forEach(c => {
            c.parent = assetAssetPanoramaGroup;
        });

        assetAssetPanoramaGroup.hotspots.forEach(h => {
            h.parent = assetAssetPanoramaGroup;
        });

        return assetAssetPanoramaGroup;
    }

    public getDefaultPanoramaVersion(currentVersion?: string): AssetPanoramaVersion {
        const panoramaVersions = this.children as Array<AssetPanoramaVersion>;

        return panoramaVersions.find(pv => pv.name == currentVersion)
            ?? panoramaVersions.find(pv => pv.assetItemId == this.defaultVersionId)
            ?? null;
    }

    public addChildren(...children: Array<AssetItem>): void {
        const panoramaVersions = children.filter(c => c instanceof AssetFile) as Array<AssetFile>;
        const hotspots = children.filter(c => c instanceof AssetHotspot) as Array<AssetHotspot>;

        this.children.push(...panoramaVersions);
        this.hotspots.push(...hotspots);
    }

    public removeChildren(...children: Array<AssetItem>): void {
        this.hotspots = this.hotspots.filter(h => !children.includes(h));
        this.children = this.children.filter(c => !children.includes(c));
    }

    public defaultVersionId?: number;
    public hotspots: Array<AssetHotspot>;
}

export class SmartObjectDto<T = JsonObject> extends AssetItem {
    public constructor() {
        super(AssetItemType.SmartObject);
    }

    public static fromJson(json: any): SmartObjectDto {
        const instance = Object.assign(new SmartObjectDto(), AssetItem.fromJson(json));
        instance.dataSource = json.dataSource;
        instance.displayPropertyPath = json.displayPropertyPath;
        instance.jsonSchema = json.jsonSchema

        return instance;
    }

    public dataSource: T;
    public displayPropertyPath?: string;
    public jsonSchema?: JsonSchemaDto;
}

export const AssetItemSorter = (x: AssetItem, y: AssetItem): number => {
    const xType = x.type;
    const yType = y.type;

    if (xType != yType) {
        if (xType == AssetItemType.Project) {
            return -1;
        }
        if (yType == AssetItemType.Project) {
            return 1;
        }

        if (xType == AssetItemType.Navigation) {
            return -1;
        }
        if (yType == AssetItemType.Navigation) {
            return 1;
        }

        if (xType == AssetItemType.Directory) {
            return -1;
        }
        if (yType == AssetItemType.Directory) {
            return 1;
        }
    }

    if (isOrderedAssetItem(x) && isOrderedAssetItem(y)) {
        return x.order - y.order;
    }

    return x.name.localeCompare(y.name);
}

function isOrderedAssetItem(assetItem: any): assetItem is IOrderedAssetItem {
    return 'order' in assetItem;
}

export function isFileMap(assetItem: AssetItem): assetItem is FileMap {
    return assetItem.type == AssetItemType.FileMap;
}

export function isStreetMap(assetItem: AssetItem): assetItem is StreetMap {
    return assetItem.type == AssetItemType.StreetMap;
}

function isNavigation(assetItem: AssetItem): assetItem is Navigation {
    return assetItem.type == AssetItemType.Navigation;
}

function isAssetDirectory(assetItem: AssetItem): assetItem is AssetDirectory {
    return assetItem.type == AssetItemType.Directory;
}

function isAssetFile(assetItem: AssetItem): assetItem is AssetFile {
    return assetItem.type == AssetItemType.File;
}

function isAssetHotspot(assetItem: AssetItem): assetItem is AssetHotspot {
    return assetItem.type == AssetItemType.Hotspot;
}

function isAssetLink(assetItem: AssetItem): assetItem is AssetLink {
    return assetItem.type == AssetItemType.Link;
}

function isAssetMapArea(assetItem: AssetItem): assetItem is AssetMapArea {
    return assetItem.type == AssetItemType.MapArea;
}

function isAssetPanoramaGroup(assetItem: AssetItem): assetItem is AssetPanoramaGroup {
    return assetItem.type == AssetItemType.PanoramaGroup;
}

function isAssetPanoramaVersion(assetItem: AssetItem): assetItem is AssetPanoramaVersion {
    return assetItem.type == AssetItemType.PanoramaVersion;
}

export enum PublicationStatus { Offline = 0, Online = 1 }

export class Project {
    constructor() {
        this.assetProject = new AssetProject();
        this.tags = new Array<Tag>();
    }

    public projectId: number;
    public assetProject: AssetProject;
    public thumbnail: DriveFile;
    public fileShares: Array<IFileShare>;
    public description: string;
    public license: CreativeCommonsLicense;
    public publicationStatus: PublicationStatus;
    public location: GeoJSON;
    public tags: Array<Tag>;
    public creationTimeUtc: Date;
    public viewCounter: number;
    public layout: any;
    public size: number;
    public owner: IIdentity;
}

export class Tag {
    public tagId: number;
    public name: string;
}

export class TagCounter extends Tag {
    public count: number;
}

export class ProjectLocation {
    public location: GeoJSON;
}

export interface ICreateProject {
    name: string;
    license: CreativeCommonsLicense;
    assetTemplateId: number;
}

export interface ICreateProjectTemplate {
    name: string,

    description: string;

    /** Id de l'élément recevant le nouveau modèle */
    assetItemId: number;

    /** Id du project source à cloner */
    assetProjectId: number;

    icon: Blob;
}

export interface IAddNavigationSystem {
    name: string,
    assetParentId: number,
    navigationType: NavigationType,
    importAllPanorama?: boolean
}

export interface IDownloadStandaloneOption {
    includeAssetFiles: boolean,
    assetPanoramaVersionIds?: Array<number>;
}

export function singleifyItemNames<T extends { name: string }>(collection: Array<T>, name: string): string {
    if (!collection.find(ai => ai.name == name)) {
        return name;
    }

    const regex = new RegExp(`^${name} \\((?<number>\\d+)\\)$`);

    let maxNumber: number = 0;

    for (let child of collection) {
        const number = parseInt(child.name.match(regex)?.groups.number);

        if (!isNaN(number)) {
            maxNumber = Math.max(maxNumber, number);
        }
    }

    return `${name} (${maxNumber + 1})`;
}

/**
 * Browse recursively children of {@link AssetItem} if exists and filter {@link AssetPanoramaVersion} leafs
 * @param items Items to filter
 * @returns Filtered leaf and theirs ancestrors
 */
export function getPanoramaVersionAscendants(items: Array<AssetItem>): Array<AssetItem> {
    if (items.length == 0) {
        return [];
    }

    let filtered: Array<AssetItem> = [];

    for (const item of items) {
        if (isTypeValid(item)) {
            item.children = getPanoramaVersionAscendants(item.children);

            if (isEmptyDirectory(item)) {
                continue;
            }

            filtered.push(item);
        }
    }

    return filtered;

    function isEmptyDirectory(item: AssetDirectory): boolean {
        return item.type == AssetItemType.Directory
            && item.children.length == 0;
    }

    function isTypeValid(item: AssetItem): item is AssetDirectory {
        return item.type == AssetItemType.Project
            || item.type == AssetItemType.PanoramaGroup
            || item.type == AssetItemType.PanoramaVersion
            || item.type == AssetItemType.Directory;
    }
}
