import { IUserLogin } from 'Api/Dto/Account/UserLogin';
import { IGenerateNewApiKeyViewModel } from 'Api/Dto/Account/ViewModels/GenerateNewApiKeyViewModel';
import HttpClient from 'Api/HttpClient';
import { inject, injectable } from 'inversify';
import { QueryException, IPaginationResult, IQueryResultBase } from 'Api/Dto/QueryResult';
import { Routes } from 'Api/Routes';
import { String } from 'typescript-string-operations';
import { IForgotPasswordViewModel } from 'Api/Dto/Account/ViewModels/ForgotPasswordViewModel';
import { IRegisterViewModel } from 'Api/Dto/Account/ViewModels/RegisterViewModel';
import { IEditEmailViewModel } from 'Api/Dto/Profile/ViewModels/EditEmailViewModel';
import { IResponseHandler } from 'Api/Infrastructure/Interfaces';
import { injectTypes } from 'App/injectTypes';

@injectable()
export class AccountService {
    constructor(
        @inject(HttpClient) httpClient: HttpClient,
        @inject(injectTypes.IResponseHandler) responseHandler: IResponseHandler
    ) {
        this._httpClient = httpClient;
        this._responseHandler = responseHandler;
    }

    public async getTokensAsync(search: string, page: number = 1, pageSize: number = 100): Promise<IPaginationResult<IUserLogin>> {
        const response: Response = await this._httpClient.getAsync(
            Routes.Api.Account.Me.Tokens,
            null,
            {
                search,
                page,
                pageSize
            });

        return this._responseHandler
            .handleResponseAsync<IPaginationResult<IUserLogin>>(response);
    }

    public async getTokenAsync(tokenId: string): Promise<IUserLogin> {
        const response: Response = await this._httpClient.getAsync(
            Routes.Api.Account.Me.Tokens,
            null,
            {
                tokenId: tokenId
            });

        return this._responseHandler.handleResponseAsync<IUserLogin>(response);
    }

    public async addTokenAsync(keyName: string): Promise<IUserLogin> {
        const response: Response = await this._httpClient.postAsync(
            Routes.Api.Account.Me.Tokens,
            {
                keyName: keyName
            } as IGenerateNewApiKeyViewModel);

        return this._responseHandler.handleResponseAsync<IUserLogin>(response);
    }

    public async deleteTokensAsync(tokenIds: Array<string>): Promise<void> {
        const response: Response = await this._httpClient.deleteAsync(
            Routes.Api.Account.Me.Tokens,
            tokenIds);

        await this._responseHandler.handleErrorsAsync(response);
    }

    public async deleteTokenAsync(tokenId: string): Promise<void> {
        const response: Response = await this._httpClient.deleteAsync(
            String.Format(Routes.Api.Account.Me.Token, tokenId),
            null);

        await this._responseHandler.handleErrorsAsync(response);
    }

    public async requestResetPassword(email: string): Promise<void> {
        const response = await this._httpClient.postFormDataAsync(
            'account/forgotpassword',
            { email: email } as IForgotPasswordViewModel
        );

        if (response.status != 204) {
            const queryResult = await response.json() as IQueryResultBase;
            throw new QueryException(queryResult.error);
        }
    }

    public async webRegisterAsync(viewModel: IRegisterViewModel): Promise<void> {
        const response = await this._httpClient.postFormDataAsync(
            'account/register',
            viewModel
        );

        if (response.status != 204) {
            const queryException = await response.json() as IQueryResultBase;
            throw new QueryException(queryException.error);
        }
    }

    public async webUpdateEmailAsync(viewModel: IEditEmailViewModel): Promise<string> {
        const response = await this._httpClient.postFormDataAsync(
            'profile/profile/editemail',
            viewModel
        );
        const queryResult = await response.json();

        if (response.status != 200) {
            throw new QueryException((queryResult as IQueryResultBase).error);
        }

        return queryResult as string;
    }

    private readonly _httpClient: HttpClient;
    private readonly _responseHandler: IResponseHandler;
}
