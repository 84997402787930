import { AssetItemType } from 'Api/Contracts/Dtos';
import { PointCloud } from 'Api/Dto/PointCloud';
import { IQueryResultBase, NotFoundException } from 'Api/Dto/QueryResult';
import { AssetItem } from 'Api/ExploreService';
import { Routes } from 'Api/Routes';
import { BinaryScanReader } from 'App/Areas/Standalone/BinaryScanReader';
import FileStorageHelper from 'App/Areas/Standalone/FileStorageHelper';
import { JSONPath } from 'jsonpath-plus';
import { String } from 'typescript-string-operations';

export default class FetchFileReader {
    constructor(storage?: FileStorageHelper) {
        this._originalFetch = window.fetch;
        this._originalFetchBind = this._originalFetch.bind(window);
        this._persistentFolder = storage || new FileStorageHelper(LocalFileSystem.PERSISTENT);
    }

    public async polyfillFetchAsync(): Promise<void> {
        await this._persistentFolder.initializeAsync();
        window.fetch = this._fetchFileReader.bind(this);
    }

    public unpolyfillFetch(): void {
        window.fetch = this._originalFetch;
    }

    private async _fetchFileReader(input: RequestInfo, init?: RequestInit): Promise<Response> {
        let isFileScheme = false;
        let url = '';

        if (input instanceof Request) {
            url = input.url;
        }
        else {
            url = input;
        }

        isFileScheme = url.startsWith('file:///')
            || url.startsWith('cdvfile://')
            || url.startsWith('filesystem:');

        const isScanApi: boolean = isFileScheme
            && url.includes('/readscanat/');

        try {
            if (isScanApi) {
                return await this.fetchScanApiAsync(url, init);
            }
            else if (isFileScheme) {
                url = url.replace('filesystem://', 'filesystem:');

                let fileExists = await this._persistentFolder.isFileExistsAsync(url);
                if (fileExists) {
                    console.info(`File found on filesystem : ${url}`);

                    let mode: 'string' | 'object' | 'Array' = 'string';
                    if ((init?.headers as any)?.get('Accept') == 'application/octet-stream') {
                        mode = 'Array';
                    }

                    let content = await this._persistentFolder.readFileAsync(url, mode);

                    return new Response(content, init);
                }
            }
        }
        catch (error) {
            if (error instanceof Error) {
                let body: BodyInit = JSON.stringify({
                    error: {
                        message: error.message
                    }
                } as IQueryResultBase);

                let response: ResponseInit = init;

                if (error instanceof NotFoundException) {
                    response.status = 404;
                }
                else {
                    response.status = 400;
                }

                return new Response(body, response);
            }
        }

        return await this._originalFetchBind(input, init);
    }

    private async fetchScanApiAsync(url: string, init: RequestInit): Promise<Response> {
        const headers = new Headers(init.headers);
        const culture = headers.get('Accept-Language').toLowerCase();

        const regex: RegExp
            = /\/(?<subscriptionId>\d+)\/\d+\/.*\/(?<projectId>\d+)\/assets\/(?<assetItemId>\d+)\/readscanat\/(?<theta>[-+]?[0-9]*\.?[0-9]+)\/(?<phi>[-+]?[0-9]*\.?[0-9]+)/g;

        const matches: RegExpExecArray = regex.exec(url);

        const {
            subscriptionId,
            projectId,
            assetItemId,
            theta,
            phi
        } = matches.groups;

        const projectUrl: string = String.Format(Routes.Api.Projects.Published, projectId);
        const projectFilePath: string
            = `${cordova.file.dataDirectory}${subscriptionId}/${projectId}/${projectUrl}`;

        const pathExpression: string
            = `$.datasources[?(@.name=="assets" && @.type=="${nameof(Array)}" && @.of=="${nameof(AssetItem)}")].value[?(@.assetItemId==${assetItemId} && @.type==${AssetItemType.PanoramaVersion})].file.guid`;

        await this._ensureFileExistsAsync(projectFilePath);

        const projectJson: JSON = await this._persistentFolder.readFileAsync(
            projectFilePath,
            'object'
        );

        const fileGuidOfAsset: string = JSONPath<Array<string>>({
            json: projectJson,
            path: pathExpression
        })[0] ?? '';

        const binaryFilePath: string = `${cordova.file.dataDirectory}${subscriptionId}/${projectId}/${culture}/asset/panorama/${fileGuidOfAsset}/${fileGuidOfAsset}`;

        await this._ensureFileExistsAsync(binaryFilePath);

        let content: ArrayBuffer = await this._persistentFolder.readFileAsync(
            binaryFilePath,
            'Array'
        );

        const binaryReader = new BinaryScanReader(content);

        let point: PointCloud = binaryReader.readValueAt(
            Number.parseFloat(phi),
            Number.parseFloat(theta)
        );

        const jsonResponse: string = JSON.stringify(point);

        return new Response(jsonResponse, init);
    }

    private async _ensureFileExistsAsync(url: string): Promise<void> {
        if (!await this._persistentFolder.isFileExistsAsync(url)) {
            throw new NotFoundException(url);
        }
    }

    public get originalFetch(): (input: RequestInfo, init?: RequestInit) => Promise<Response> {
        return this._originalFetchBind;
    }

    private _originalFetch: (input: RequestInfo, init?: RequestInit) => Promise<Response> = null;
    private _originalFetchBind: (input: RequestInfo, init?: RequestInit) => Promise<Response> = null;
    private _persistentFolder: FileStorageHelper = null;
}

