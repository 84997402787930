import { PublicationStatus, Tag } from 'Api/Contracts/Dtos';
import { ITagService } from 'Api/Contracts/Interfaces';
import { IFileShare } from 'Api/Dto/Drive';
import { IPaginationResult, IQueryResultBase, IServerPaginationResult, NotFoundException, QueryException, UnauthorizedException } from 'Api/Dto/QueryResult';
import { ISearchResult } from 'Api/Dto/SearchResult';
import { IUpdateVideoViewModel, IVideo } from 'Api/Dto/Video';
import { IComment } from 'Api/ExploreService';
import HttpClient from 'Api/HttpClient';
import { IResponseHandler } from 'Api/Infrastructure/Interfaces';
import { Routes } from 'Api/Routes';
import { TagService } from 'Api/Services';
import { injectTypes } from 'App/injectTypes';
import JsonPatch from 'fast-json-patch';
import { inject, injectable } from 'inversify';
import { String } from 'typescript-string-operations';

@injectable()
export class VideoService implements ITagService<IVideo> {
    constructor(
        @inject(HttpClient) httpClient: HttpClient,
        @inject(TagService) tagService: TagService,
        @inject(injectTypes.IResponseHandler) responseHandler: IResponseHandler
    ) {
        this._httpClient = httpClient;
        this._tagService = tagService;
        this._responseHandler = responseHandler;
    }

    public async searchVideoAsync(search: string, page: number, pageSize: number): Promise<IPaginationResult<IVideo>> {
        const response: Response = await this._httpClient.getAsync(Routes.Api.Videos.Base, null, {
            search,
            page,
            pageSize
        });

        return this._responseHandler.handleResponseAsync<IPaginationResult<IVideo>>(response);
    }

    public async deleteVideosAsync(videoIds: Array<number>) {
        const response = await this._httpClient.deleteAsJsonAsync(videoIds, null, Routes.Api.Videos.Base);

        if (response.status != 204) {
            const queryError = await response.json() as IQueryResultBase;

            throw new QueryException(queryError.error);
        }
    }

    public async getVideoAsync(videoId: number, token?: string): Promise<IVideo> {
        const url = String.Format(Routes.Api.Videos.Video, videoId);
        const response = await this._httpClient.getAsync(url, null, { token: token });
        const queryResult = await response.json();

        if (response.status == 404) {
            throw new NotFoundException();
        }

        if (response.status == 401) {
            throw new UnauthorizedException();
        }

        if (response.status != 200) {
            throw new QueryException((queryResult as IQueryResultBase).error);
        }

        return queryResult as IVideo;
    }

    public async updateVideoAsync(original: IVideo, updated: IUpdateVideoViewModel): Promise<void> {
        const patch = JsonPatch.compare(original, updated);
        const url = String.Format(Routes.Api.Videos.Video, original.videoId);
        const response = await this._httpClient.patchAsJsonAsync(patch, null, url);

        if (response.status != 204) {
            const queryError = await response.json() as IQueryResultBase;
            throw new QueryException(queryError.error);
        }

        JsonPatch.applyPatch(original, patch);
    }

    public searchTagsAsync(search: string): Promise<Tag[]> {
        return this._tagService.searchTagsAsync(search);
    }

    public async saveTagsAsync(item: IVideo, tags: Tag[]): Promise<void> {
        await this.setTagsAsync(item.videoId, tags.map(t => t.name));
    }

    public async setTagsAsync(videoId: number, tags: string[]): Promise<Array<Tag>> {
        const url = String.Format(Routes.Api.Videos.Tags, videoId);
        const response = await this._httpClient.putAsJsonAsync(tags, null, url);

        const queryResult = await response.json();

        if (response.status != 201) {
            throw new QueryException((queryResult as IQueryResultBase).error);
        }

        return queryResult as Array<Tag>;
    }

    public async getTagsAsync(videoId: number, token?: string): Promise<Tag[]> {
        const url = String.Format(Routes.Api.Videos.Tags, videoId);
        const response = await this._httpClient.getAsync(url, null, { token: token });
        const queryResult = await response.json();

        if (response.status != 200) {
            throw new QueryException((queryResult as IQueryResultBase).error);
        }

        return queryResult as Array<Tag>;
    }

    public async getSharesAsync(videoId: number, token?: string): Promise<Array<IFileShare>> {
        const url = String.Format(Routes.Api.Videos.Shares, videoId);
        const response = await this._httpClient.getAsync(url, null, { token: token });
        const queryResult = await response.json();

        if (response.status != 200) {
            throw new QueryException((queryResult as IQueryResultBase).error);
        }

        return queryResult as Array<IFileShare>;
    }

    public async updateThumbnailAsync(videoId: number, picture: File): Promise<string> {
        const url = String.Format(Routes.Api.Videos.Thumbnail, videoId);
        const data = {
            file: picture
        };
        const response = await this._httpClient.postFormDataAsync(url, data);

        if (response.status != 201) {
            const queryResult = await response.json() as IQueryResultBase;
            throw new QueryException(queryResult.error);
        }

        return response.headers.get('location');
    }

    public async deleteThumbnailAsync(videoId: number): Promise<void> {
        const url = String.Format(Routes.Api.Videos.Thumbnail, videoId);
        const response = await this._httpClient.deleteAsync(url, null);

        if (response.status != 204) {
            const queryResult = await response.json() as IQueryResultBase;
            throw new QueryException(queryResult.error);
        }
    }

    public async updatePublicationStatusAsync(videoId: number, status: PublicationStatus): Promise<void> {
        const url = String.Format(Routes.Api.Videos.PublicationStatus, videoId);
        const response = await this._httpClient.putAsJsonAsync(status, null, url);

        if (response.status != 204) {
            const queryError = await response.json() as IQueryResultBase;
            throw new QueryException(queryError.error);
        }
    }

    public async getPublishedVideosAsync(page: number, pageSize: number, orderBy: string): Promise<IPaginationResult<IVideo>> {
        const response = await this._httpClient.getAsync(
            Routes.Api.Videos.Published.Base,
            null,
            {
                page,
                pageSize,
                orderBy
            }
        );

        return this._responseHandler
            .handleResponseAsync<IPaginationResult<IVideo>>(response);
    }

    public async getPublishedVideoAsync(videoId: number, token?: string): Promise<IVideo> {
        const url = String.Format(Routes.Api.Videos.Published.Video, videoId);

        const queryResult = await this._responseHandler.handleResponseAsync<IVideo>(
            await this._httpClient.getAsync(url, null, { token: token })
        );

        return queryResult;
    }

    public async addViewToVideoAsync(videoId: number, token?: string): Promise<void> {
        let url = String.Format(Routes.Api.Videos.Published.AddView, videoId);

        if (token) {
            const queryParameters = new URLSearchParams({ token: token });
            url = `${url}?${queryParameters}`;
        }

        const response = await this._httpClient.postAsJsonAsync(null, null, url);

        await this._responseHandler.handleErrorsAsync(response);
    }

    public async getCommentsAsync(videoId: number, page: number, pageSize: number, token?: string): Promise<IPaginationResult<IComment>> {
        const data = {
            token,
            page,
            pageSize
        };

        const response: Response = await this._httpClient.getAsync(
            String.Format(Routes.Api.Videos.Published.Comments, videoId),
            null,
            data);

        return this._responseHandler
            .handleResponseAsync<IPaginationResult<IComment>>(response);
    }

    public async addCommentAsync(videoId: number, text: string, token?: string): Promise<IComment> {
        let url = String.Format(Routes.Api.Videos.Published.Comments, videoId);

        if (token) {
            const queryParameters = new URLSearchParams({ token: token });
            url = `${url}?${queryParameters}`;
        }

        const queryResult = await this._responseHandler.handleResponseAsync<IComment>(
            await this._httpClient.postAsJsonAsync({ text: text }, null, url)
        );

        return queryResult;
    }

    public async searchPublishedVideosAsync(searchText: string, maxPageSize: number): Promise<ISearchResult<IVideo>> {
        const queryData = {
            searchText: searchText,
            maxPageSize: maxPageSize
        };

        const response = await this._httpClient.getAsync(Routes.Api.Videos.Published.Search, null, queryData);

        return await this._handleSearchResponseAsync(response);
    }

    public async searchPublishedVideosNextPageAsync(nextPageLink: string): Promise<ISearchResult<IVideo>> {
        const response = await this._httpClient.getAsync(nextPageLink, '/');

        return await this._handleSearchResponseAsync(response);
    }

    private async _handleSearchResponseAsync(response: Response): Promise<ISearchResult<IVideo>> {
        const queryResult = await response.json();

        if (response.status != 200) {
            throw new QueryException((queryResult as IQueryResultBase).error);
        }

        const paginatedResult = queryResult as IServerPaginationResult<IVideo>;

        return {
            found: paginatedResult.result,
            nextLink: paginatedResult.nextLink,
            hasMoreResults: paginatedResult.nextLink != null
        }
    }

    private readonly _httpClient: HttpClient;
    private readonly _tagService: TagService;
    private readonly _responseHandler: IResponseHandler;
}
