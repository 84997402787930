import { AppContext } from 'Api/AppContext';
import { AssetDirectory, AssetFile, AssetItem, AssetItemType, AssetLink, AssetPanoramaGroup, AssetPanoramaVersion, AssetProject, Navigation, NavigationType, Project, ProjectMap, SearchOption } from 'Api/Contracts/Dtos';
import { WaypointGraph } from 'Api/Contracts/Types';
import { FileTaskResult, Guid, WorkerTaskState } from 'Api/Dto/Drive';
import { IDefaultHotspotProperties, IDefaultShapeHotspotProperties } from 'Api/Dto/Project/DefaultHotspotProperties';
import { ApiException } from 'Api/Dto/QueryResult';
import { DrivePermissions } from 'Api/Enums/Permissions';
import { ProjectService, WorkerService } from 'Api/Services';
import { AssetItemStatePollingBehavior } from 'App/Services/PollingBehaviors/AssetItemStatePollingBehavior';
import { filterRecursive } from 'Commons/Common';
import EnumHelper from 'Framework/Helpers/EnumHelper';
import { IPollingResult, PollingManager } from 'Framework/Services/PollingManager';
import { injectable } from 'inversify';
import Vue from 'vue';

export type AssetError = 'EmptyAssetPanoramaGroupError' | 'NotAssetPanoramaVersionError' | 'FileStateError' | 'MissingDefaultPanoramaVersionError';

@injectable()
export default class EditProjectViewModel {
    public constructor(projectService: ProjectService, workerService: WorkerService, appContext: AppContext) {
        this.projectService = projectService;
        this.workerService = workerService;
        this.appContext = appContext;

        this.assetsStatePollingManager = new PollingManager(
            10_000,
            new AssetItemStatePollingBehavior(
                this.projectService._driveService,
                (results: Array<IPollingResult<AssetFile, FileTaskResult>>) => this._onFileStatePollingAsync(results),
                null
            )
        );
    }

    public async loadProjectAsync(projectId: number): Promise<void> {
        this.projectId = projectId;

        this.project = await this.projectService.getProjectById(this.projectId);
        this.project.tags = await this.projectService.getTagsAsync(this.projectId);
        let [first] = await this.projectService.getAssets(this.project.assetProject.assetItemId, SearchOption.AllDirectories);
        this.waypointGraph = await this.projectService.getWaypointGraphAsync(this.projectId);

        if (first instanceof AssetProject) {
            if (this.project.assetProject != null) {
                Object.assign(first.fileShares, this.project.assetProject.fileShares);
                Object.assign(this.project.assetProject, first);
            }

            this.project.assetProject = first;
        }

        this.assetProject = this.project.assetProject;
        this.assetProject.project = this.project;
        this.assetItemsSource = [this.assetProject];
    }

    private async _onFileStatePollingAsync(results: Array<IPollingResult<AssetFile, FileTaskResult>>) {
        results = results.filter(r => r.item.type == AssetItemType.File && r.result.state != WorkerTaskState.Created);

        const assetItemIds = [...new Set(results.map(r => r.item.assetItemId))];

        const updatedAssets = await this.projectService.getAssetsAsync(this.projectId, assetItemIds);

        for (const result of results) {
            const asset = result.item;
            const assetUpdated = updatedAssets.find(a => asset.assetItemId == a.assetItemId);

            // If the worker added the panorama to a new PanoramaGroup
            if (asset.parentId != assetUpdated.parentId) {
                let newParent = await this.projectService.getAssetAsync<AssetDirectory>(
                    this.project.projectId,
                    assetUpdated.parentId
                );

                let oldParent = asset.parent;

                oldParent.children.push(newParent);
                newParent.children = [asset];
                oldParent.children.splice(oldParent.children.indexOf(asset), 1);
                asset.parent = newParent;
            }

            if (asset.type != assetUpdated.type) {
                assetUpdated.parent = asset.parent;

                (asset as any).__proto__ = (assetUpdated as any).__proto__;

                for (const key in assetUpdated) {
                    Vue.set(asset, key, assetUpdated[key]);
                }

                if (assetUpdated.type == AssetItemType.PanoramaVersion) {
                    const parent = asset.parent as AssetPanoramaGroup;

                    if (!parent.defaultVersionId) {
                        parent.defaultVersionId = asset.assetItemId;
                    }

                    const document = this.itemsOpenedInEditor.find(d => d.data == asset);

                    if (document) {
                        document.data = parent;
                    }
                }
            }
        }
    }

    public selectTreeviewNode(assetItem: AssetItem, onlyOneNode: boolean = false): void {
        if (onlyOneNode) {
            this.selectedItems = [];
        }

        if (!this.selectedItems.includes(assetItem)) {
            this.selectedItems.push(assetItem);
        }
    }

    public async restartTaskAsync(asset: AssetFile): Promise<void> {
        if (asset instanceof AssetFile) {
            await this.workerService.restartTaskAsync(asset.file.state.taskId);
            this.assetsStatePollingManager.addItems([asset]);
        }
    }

    public findAssets(predicate: (ai: AssetItem) => boolean): Array<AssetItem> {
        return [...filterRecursive(predicate, [this.project.assetProject], 'children')];
    }

    public findAssetById(assetId: number): AssetItem {
        const [first] = this.findAssets(ai => ai.assetItemId == assetId);
        return first ?? null;
    }

    public recursiveActionOnAssetItem(itemsSource: Array<AssetItem>, action: (assetItem: AssetItem) => void) {
        for (let item of itemsSource) {
            action(item);
            if (item instanceof AssetDirectory) {
                this.recursiveActionOnAssetItem(item.children, action);
            }
        }
    }

    public async deleteAssetItemsAsync(itemsToDelete: Array<AssetItem>): Promise<void> {
        const idsToDelete: Array<number> = itemsToDelete.map(item => item.assetItemId);

        try {
            await this.projectService.deleteAssets(
                this.projectId,
                idsToDelete);
        }
        catch (ex) {
            if (ex instanceof ApiException) {
                throw new Error(ex.error.message);
            }
        }

        const deletedItemsIds: Array<number> = this._deleteAssetNodes(
            this.assetProject,
            idsToDelete,
            false);

        this._deleteLinkedAssetNodes(this.assetProject, deletedItemsIds);
    }

    private _deleteLinkedAssetNodes(node: AssetItem, linkedIds: Array<number>): void {
        if (node instanceof AssetDirectory) {
            let children = [...node.children];

            if (node instanceof AssetPanoramaGroup) {
                children.push(...node.hotspots);
            }

            children.forEach(child => {
                if (child instanceof AssetLink) {
                    if (child.assetItemLinkedId && linkedIds.includes(child.assetItemLinkedId)) {
                        if (node instanceof AssetPanoramaGroup) {
                            node.removeChildren(child);
                        }
                        else {
                            Vue.delete(node.children, node.children.indexOf(child));
                        }
                    }
                }

                this._deleteLinkedAssetNodes(child, linkedIds);
            });
        }
    }

    private _deleteAssetNodes(node: AssetItem, idsToDelete: Array<number>, deleteThis: boolean): Array<number> {
        let deleteds: Array<number> = deleteThis
            ? [node.assetItemId]
            : [];

        if (node instanceof AssetDirectory && (idsToDelete.length > 0 || deleteThis)) {
            let children = [...node.children];

            if (node instanceof AssetPanoramaGroup) {
                children.push(...node.hotspots);
            }

            children.forEach((child) => {
                let childIndexInToDelete = idsToDelete.indexOf(child.assetItemId);

                if (childIndexInToDelete >= 0) {
                    idsToDelete.splice(childIndexInToDelete, 1);

                    if (node instanceof AssetPanoramaGroup) {
                        node.removeChildren(child);

                        if (child.assetItemId == node.defaultVersionId) {
                            node.defaultVersionId = null;
                        }
                    }
                    else {
                        Vue.delete(node.children, node.children.indexOf(child));
                    }
                }

                deleteds.push(...this._deleteAssetNodes(child, idsToDelete, deleteThis || childIndexInToDelete >= 0));
            });
        }

        return deleteds;
    }

    public getThumbnailUri(item: AssetItem): string {
        let guid: string = null;

        if (item instanceof AssetProject) {
            guid = item.project.thumbnail
                ? item.project.thumbnail.guid
                : null;
        }
        else if (item instanceof ProjectMap) {
            guid = item.file?.guid;
        }
        else if (item instanceof AssetPanoramaGroup) {
            guid = item.getDefaultPanoramaVersion()?.file?.guid;
        }
        else if (item instanceof AssetPanoramaVersion) {
            guid = item.file?.guid;
        }
        else if (item instanceof AssetDirectory) {
            guid = item.file?.thumbnailGuid;
        }
        else if (item instanceof AssetFile) {
            guid = item.file?.guid;
        }
        guid = guid ?? Guid.emptyGuid;

        return `/${this.appContext.codeCulture}/asset/preview/${guid}?t=${Date.now().toString()}`;
    }

    public getDriveUrl(item: AssetItem): string {
        const file = item instanceof AssetDirectory
            ? item.file
            : item.parent.file;

        if (!file) {
            return '';
        }

        return `${this.appContext.codeCulture}/drive/?fid=${file.guid}`;
    }

    public getErrors(item: AssetItem): Array<AssetError> {
        if (!this.isVersioningEnabled && item instanceof AssetPanoramaGroup) {
            item = item.getDefaultPanoramaVersion();
        }

        const errors: Array<AssetError> = [];

        if (item.parent instanceof AssetPanoramaGroup
            && item instanceof AssetFile
            && !([WorkerTaskState.Created, WorkerTaskState.Running, null].includes(item.file.state?.state)
            )
            && item.type != AssetItemType.PanoramaVersion
        ) {
            errors.push('NotAssetPanoramaVersionError');
        }

        if (item instanceof AssetPanoramaGroup) {
            if (item.children.length == 0) {
                errors.push('EmptyAssetPanoramaGroupError');
            }

            if (item.defaultVersionId == null) {
                errors.push('MissingDefaultPanoramaVersionError');
            }
        }

        if (item instanceof AssetFile
            && ![AssetItemType.Project, AssetItemType.PanoramaGroup].includes(item.type)
            && [WorkerTaskState.Canceled, WorkerTaskState.Faulted].includes(item.file.state?.state)
        ) {
            errors.push('FileStateError');
        }

        return errors;
    }

    public hasError(item: AssetItem): boolean {
        return this.getErrors(item).length > 0;
    }

    public addProcessableAssetFilesToPollingQueue(assetItemsSource: Array<AssetItem>) {
        const assetFiles = assetItemsSource.filter(
            ai => ai instanceof AssetFile
                && EditProjectViewModel.processableAssetItemTypes.includes(ai.type)
        ) as Array<AssetFile>;

        this.assetsStatePollingManager.addItems(assetFiles);
    }

    public openTreeviewNode(assetItem: AssetItem): void {
        if (!this.openedFolders.includes(assetItem)) {
            this.openedFolders.push(assetItem);
        }
    }

    public unselectTreeviewNode(assetItem: AssetItem): void {
        const indexOf = this.selectedItems.indexOf(assetItem);

        if (indexOf >= 0) {
            this.selectedItems.splice(indexOf, 1);
        }
    }

    public openItemInEditor(assetItem: AssetItem): void {
        if (!this.itemsOpenedInEditor.find(d => d.data == assetItem)) {
            this.itemsOpenedInEditor.push(new Document<AssetItem>(assetItem));
        }
    }

    public closeItemInEditor(assetItem: AssetItem): void {
        const index = this.itemsOpenedInEditor.findIndex(d => d.data == assetItem);

        if (index >= 0) {
            Vue.delete(this.itemsOpenedInEditor, index);
        }
    }

    public closeItemInEditorById(documentId: number): void {
        const index = this.itemsOpenedInEditor.findIndex(d => d.id == documentId);

        if (index >= 0) {
            Vue.delete(this.itemsOpenedInEditor, index);
        }
    }

    public focusItemInEditor(assetItem: AssetItem): void {
        const id = this.itemsOpenedInEditor.find(d => d.data == assetItem)?.id;

        if (id) {
            this.activePanelId = `editor-${id}`;
        }
    }

    public get navigations(): Array<Navigation> {
        return this.assetProject
            ?.children
            .filter(ai => ai.type == AssetItemType.Navigation) as Array<Navigation>
            ?? [];
    }

    public get hasMapNavigation(): boolean {
        return this.navigations.some(n => n.navigationType == NavigationType.Map);
    }

    public get hasListNavigation(): boolean {
        return this.navigations.some(n => n.navigationType == NavigationType.List);
    }

    public get selectedElementsCount(): number {
        return this.selectedItems.length;
    }

    public get selectedItem(): AssetItem {
        const [first] = this.selectedItems;

        return first ?? null;
    }

    public get hasPanoramaGroups(): boolean {
        function usesVersioning(ai: AssetItem): boolean {
            if (ai instanceof AssetPanoramaGroup) {
                return ai.children.length != 1
                    || ai.defaultVersionId == null
                    || ai.children[0].name != ai.name
                    || ai.hotspots.length > 0;
            }

            return false;
        }

        return this.findAssets(ai => usesVersioning(ai))
            .length > 0;
    }

    public get hasViewFilePermission(): boolean {
        return EnumHelper.hasFlag(this.appContext.user.permissions.drive, DrivePermissions.ViewFile);
    }

    public get hasDownloadFilePermission(): boolean {
        return EnumHelper.hasFlag(this.appContext.user.permissions.drive, DrivePermissions.DownloadFile);
    }

    public get hasFileSharePermissions(): boolean {
        return EnumHelper.hasOneOfFlags(
            this.appContext.user.permissions.drive,
            DrivePermissions.ShareFile | DrivePermissions.ShareFolder
        );
    }

    public defaultHotspots: { [key: string]: IDefaultHotspotProperties } = {
        map: {
            icon: 'fa-regular fa-location-dot',
            color: '#3e95f8ab',
            size: 20
        },
        panorama: {
            icon: 'animated concentric-in',
            color: '#000000ff',
            size: 20
        }
    };

    public defaultShapeHotspots: { [key: string]: IDefaultShapeHotspotProperties } = {
        map: {
            color: '#3e95f8ab',
            hoverColor: 'rgba(255,0,0,0.667)',
            selectedColor: 'rgba(0,255,0,0.667)',
            stroke: {
                color: 'rgba(0,0,0,0.667)',
                width: 0
            }
        },
        panorama: {
            color: '#ffffff18',
            hoverColor: '#d8d8d8ab',
            selectedColor: '',
            stroke: {
                color: '#525252ff',
                width: 0
            }
        }
    }

    public AssetItemType: any = AssetItemType;
    public projectId: number = 0;
    public project: Project = null;
    public assetProject: AssetProject = null;

    public waypointGraph: WaypointGraph = null;

    //Binding Treeview
    public assetItemsSource: Array<AssetItem> = [];
    public selectedItems: Array<AssetItem> = [];
    public openedFolders: Array<AssetItem> = [];

    public itemsOpenedInEditor: Array<Document<AssetItem>> = [];
    public activePanelId: string = null;
    public activeDocumentAssetItem: AssetItem = null;

    public isVersioningEnabled: boolean = true;

    public readonly assetsStatePollingManager: PollingManager<AssetFile, FileTaskResult>;

    public readonly projectService: ProjectService;
    public readonly workerService: WorkerService;
    public readonly appContext: AppContext;

    public static readonly processableAssetItemTypes: Array<AssetItemType> = [AssetItemType.File, AssetItemType.FileMap, AssetItemType.PanoramaVersion];
}

class Document<T> {
    public constructor(data: T) {
        this.data = data;
        this.id = Document.CurrentId++;
    }

    public readonly id: number;
    public data: T;

    private static CurrentId: number = 0;
}
