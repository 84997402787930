import { AssetDirectory, AssetFile, AssetItem, AssetItemType, AssetPanoramaGroup, AssetProject, AssetTemplate, FileMap, IAddNavigationSystem, ICreateProject, ICreateProjectTemplate, IDownloadStandaloneOption, isFileMap, isStreetMap, IUpdateProjectItemViewModel, ManipulationState, Navigation, NavigationType, Project, ProjectLocation, ProjectMap, PublicationStatus, SearchOption, SortOptions, StreetMap, Tag } from 'Api/Contracts/Dtos';
import { IGexfWaypointAttributesDto } from 'Api/Contracts/Dtos/WaypointGraph';
import { ITagService } from 'Api/Contracts/Interfaces';
import { ICartesianCoordinatesDto, WaypointAttributes, WaypointEdgeAttributes, WaypointGraph } from 'Api/Contracts/Types';
import { IEditProjectTemplateViewModel } from 'Api/Dto/Admin/ViewModels/Projects';
import { File as DriveFile } from 'Api/Dto/Drive';
import { IAddAssetPanoramaGroupViewModel } from 'Api/Dto/Project/ViewModels/AddAssetPanoramaGroupViewModel';
import IAssetViewModel from 'Api/Dto/Project/ViewModels/AssetViewModel';
import { IManipulateItemsViewModel } from 'Api/Dto/Project/ViewModels/ManipulateItemsViewModel';
import { IPaginationResult, IQueryResultBase, QueryException, UnauthorizedException } from 'Api/Dto/QueryResult';
import { ChunkedFile, FileUploader } from 'Api/FileUploader';
import HttpClient from 'Api/HttpClient';
import { IResponseHandler } from 'Api/Infrastructure/Interfaces';
import { Routes } from 'Api/Routes';
//Don't import from 'Api/Services' to prevent circular dependencies
import { DriveService } from 'Api/Services/DriveService';
import { CreateFileResult } from 'Api/ViewModel/Drive';
import { injectTypes } from 'App/injectTypes';
import JsonPatch, { Operation } from 'fast-json-patch';
import Graph from 'graphology';
import gexf from 'graphology-gexf';
import { inject, injectable } from 'inversify';
import { String } from 'typescript-string-operations';


@injectable()
export class ProjectService implements ITagService<Project> /*implements IProjectService*/ {
    constructor(
        @inject(HttpClient) httpClient: HttpClient,
        @inject(DriveService) driveService: DriveService,
        @inject(injectTypes.IResponseHandler) responseHandler: IResponseHandler
    ) {
        this._httpClient = httpClient;
        this._driveService = driveService;
        this._responseHandler = responseHandler;
    }

    private static responseToProject(item: any): Project {
        let project: Project = new Project();
        project.projectId = item.projectId;
        project.thumbnail = item.thumbnail;
        project.fileShares = item.fileShares;
        project.description = item.description ?? '';
        project.license = item.license;
        project.publicationStatus = item.publicationStatus;
        project.location = item.location;
        project.tags = item.tags;
        project.creationTimeUtc = new Date(item.creationTimeUtc);
        project.viewCounter = item.viewCounter;
        project.size = item.size;
        project.layout = item.layout;
        project.assetProject = AssetItem.jsonToObject<AssetProject>(item.assetProject);
        project.owner = item.owner;
        //project.projectLocation = Object.assign(new ProjectLocation, project.projectLocation);
        return project;
    }
    private static responseToTagProject(item: any): Tag {
        let tag: Tag = Object.assign(new Tag(), item);
        return tag;
    }

    public async createProjectAsync(newProject: ICreateProject): Promise<Project> {
        const response = await this._httpClient.postAsJsonAsync(newProject, null, Routes.Api.Projects.Base);
        const jsonResult = await response.json();

        if (response.status == 400) {
            let queryError = jsonResult as IQueryResultBase;

            throw new QueryException(queryError.error);
        }

        return jsonResult as Project;
    }

    public async createProjectTemplateAsync(newTemplate: ICreateProjectTemplate): Promise<Project> {
        let icon = newTemplate.icon;
        delete newTemplate.icon;

        let response = await this._httpClient.postAsync(Routes.Api.Projects.Templates.Base, newTemplate);
        let jsonResult = await response.json();

        if (response.status != 201) {
            let queryError = jsonResult as IQueryResultBase;

            throw new QueryException(queryError.error);
        }

        let queryResult = jsonResult as Project;

        if (icon) {
            await this.editProjectThumbnail(queryResult.projectId, icon);
        }

        return queryResult;
    }

    public async createNewProjectTypeAsync(name: string): Promise<AssetDirectory> {
        let response = await this._httpClient.postAsync(
            Routes.Api.Projects.Types.Base,
            { name: name }
        );

        let queryResult = await response.json();

        if (response.status == 400) {
            let queryError = queryResult as IQueryResultBase;

            throw new QueryException(queryError.error);
        }

        return queryResult as AssetDirectory;
    }

    public async getProjectById(projectId: number): Promise<Project> {
        const response = await this._httpClient.getAsync(`/project/home/project?projectId=${projectId}`);
        const item = await response.json();

        return ProjectService.responseToProject(item);
    }

    public async searchProjectAsync(search: string, sortOrder?: SortOptions, page: number = 1, pageSize: number = 100): Promise<IPaginationResult<Project>> {
        let url = '/project/home/searchprojects';

        const response: Response = await this._httpClient.getAsync(url, null, {
            search,
            sortOrder,
            page,
            pageSize
        });

        const result: IPaginationResult<Project> = await this._responseHandler
            .handleResponseAsync<IPaginationResult<Project>>(response);

        result.items = result.items.map(project => ProjectService.responseToProject(project));

        return result;
    }

    public async getTagsAsync(projectId: number): Promise<Array<Tag>> {
        const response = await this._httpClient.getAsync(
            String.Format(Routes.Api.Projects.Tags, projectId)
        );
        let queryResult = await response.json();

        if (response.status != 200) {
            //TODO handle errors
        }

        const tags = queryResult as Array<Tag>;

        return tags.map(tag => ProjectService.responseToTagProject(tag));
    }

    public searchTagsAsync(search: string): Promise<Array<Tag>> {
        //TODO refactor with import tagservice
        return this._driveService.searchTagsAsync(search);
    }

    public async saveTagsAsync(project: Project, tags: Array<Tag>): Promise<void> {
        let tagNames = tags.map(t => t.name);
        const url = String.Format(Routes.Api.Projects.Tags, project.projectId);

        const response = await this._httpClient.putAsJsonAsync(tagNames, null, url);

        if (response.status != 201) {
            let queryResult: IQueryResultBase = await response.json();

            throw new Error(queryResult.error.message);
        }
    }

    public async getAssets(assetId: number, searchOption: SearchOption = SearchOption.TopDirectoryOnly): Promise<Array<AssetItem>> {
        const response = await this._httpClient.getAsync(`/project/home/assets?id=${assetId}&searchOption=${searchOption}`);
        const items = await response.json();

        return AssetItem.jsonToArray<AssetItem>(items);
    }

    public async getAssetAsync<T extends AssetItem = AssetItem>(projectId: number, assetId: number): Promise<T> {
        const url = String.Format(Routes.Api.Projects.Assets.Asset, projectId, assetId);
        const response = await this._httpClient.getAsync(url);
        const item = await response.json();

        if (response.status != 200) {
            const queryError = item as IQueryResultBase;

            throw new QueryException(queryError.error);
        }

        const assetItem = item as T;
        return AssetItem.jsonToObject<T>(assetItem);
    }

    public async getAssetsAsync(projectId: number, assetIds: Array<number>): Promise<Array<AssetItem>> {
        const url = String.Format(Routes.Api.Projects.Assets.Base, projectId);

        const response = await this._httpClient.postAsync(url, assetIds);
        const jsonResponse = await response.json();

        if (response.status != 200) {
            const queryError = jsonResponse as IQueryResultBase;
            throw new QueryException(queryError.error);
        }

        const jsonItem = jsonResponse as Array<AssetItem>;
        return AssetItem.jsonToArray<AssetItem>(jsonItem);
    }

    public async addNewFolderAsync(name: string, parent: AssetDirectory): Promise<AssetDirectory> {
        const url = String.Format(Routes.Api.Projects.Assets.Children, parent.assetItemId);
        const model = {
            name,
            type: AssetItemType.Directory
        };

        const response = await this._httpClient.postAsync(url, model);
        const jsonResponse = await response.json();

        if (response.status != 201) {
            const queryError = jsonResponse as IQueryResultBase;
            throw new QueryException(queryError.error);
        }

        const jsonItem = jsonResponse as AssetDirectory;
        const assetDirectory = AssetItem.jsonToObject<AssetDirectory>(jsonItem);

        assetDirectory.parent = parent;
        parent.addChildren(assetDirectory);

        return assetDirectory;
    }

    public async addNewAssetPanoramaGroupAsync(name: string, parent: AssetDirectory): Promise<AssetPanoramaGroup> {
        let model: IAddAssetPanoramaGroupViewModel = {
            name: name,
            parentId: parent.assetItemId
        };

        const response = await this._httpClient.postAsync('/project/home/addassetpanoramagroup', model);
        const jsonResponse = await response.json();

        if (response.status == 400) {
            const queryError = jsonResponse as IQueryResultBase;
            throw new QueryException(queryError.error);
        }

        const jsonItem = jsonResponse as AssetPanoramaGroup;
        const assetPanoramaGroup = AssetItem.jsonToObject<AssetPanoramaGroup>(jsonItem);

        assetPanoramaGroup.parent = parent;
        parent.addChildren(assetPanoramaGroup);

        return assetPanoramaGroup;
    }

    public async addNavigationFolder(name: string, parent: AssetDirectory, navigationType: NavigationType, doImportAllPanoramas: boolean): Promise<Navigation> {
        const model: IAddNavigationSystem = {
            name: name,
            assetParentId: parent.assetItemId,
            navigationType: navigationType,
            importAllPanorama: doImportAllPanoramas
        };

        const response = await this._httpClient.postAsync('/project/home/addnavigationdirectory', model);
        const queryResult = await response.json();

        if (response.status != 201) {
            const queryError = queryResult as IQueryResultBase;
            throw new QueryException(queryError.error);
        }

        const navigation = AssetItem.jsonToObject<Navigation>(queryResult);

        navigation.parent = parent;
        parent.addChildren(navigation);

        return navigation;
    }

    public async addNewAssets<T extends AssetItem>(projectId: number, model: Array<T | IAssetViewModel>, parent: AssetDirectory): Promise<Array<T>> {
        const clonedModel = model.map(ai => {
            const clone = { ...ai };
            clone.parentId = parent.assetItemId;

            if (ai instanceof AssetItem) {
                //avoid circular reference when serializing to JSON
                delete clone['parent'];
                //assetItemId must be server generated when creating new item
                delete clone['assetItemId'];
            }

            return clone;
        });

        const response = await this._httpClient.postAsync(
            '/project/home/addassetitems',
            {
                projectId,
                assets: clonedModel
            }
        );
        const queryResult = await response.json();

        if (response.status != 201) {
            const queryError = queryResult as IQueryResultBase;
            throw new QueryException(queryError.error);
        }

        const items = AssetItem.jsonToArray<T>(queryResult);

        items.forEach(ai => {
            ai.parent = parent;
        });

        parent.addChildren(...items);

        return items;
    }

    public async addNewStreetMap(model: StreetMap, parent: Navigation): Promise<StreetMap> {
        model.parentId = parent.assetItemId;

        const response = await this._httpClient.postAsync('/project/home/addstreetmapitem', model);
        const queryResult = await response.json();

        if (response.status != 201) {
            const queryError = queryResult as IQueryResultBase;
            throw new QueryException(queryError.error);
        }

        const jsonItem = queryResult as StreetMap;
        const item = AssetItem.jsonToObject<StreetMap>(jsonItem);

        item.parent = parent;
        parent.addChildren(item);

        return item;
    }

    public async cloneAssetItems(assetItems: Array<AssetItem>, destination: AssetDirectory): Promise<Array<AssetItem>> {
        const model: IManipulateItemsViewModel = {
            assetItemIds: assetItems.map(ai => ai.assetItemId),
            destinationAssetItemId: destination.assetItemId,
            state: ManipulationState.Copy
        };

        const response = await this._httpClient.postAsync('/project/home/manipulateassetitem', model);
        const jsonResponse = await response.json();

        if (response.status != 200) {
            const queryError = jsonResponse as IQueryResultBase;
            throw new QueryException(queryError.error);
        }

        const jsonItem = jsonResponse as Array<AssetItem>;
        const items = AssetItem.jsonToArray<AssetItem>(jsonItem);

        destination.addChildren(...items);
        items.forEach(ai => {
            ai.parent = destination;
        });

        return items;
    }

    public async moveAssetItems(assetItems: Array<AssetItem>, destination: AssetDirectory): Promise<void> {
        const model: IManipulateItemsViewModel = {
            assetItemIds: assetItems.map(ai => ai.assetItemId),
            destinationAssetItemId: destination.assetItemId,
            state: ManipulationState.Cut
        };

        const response = await this._httpClient.postAsync('/project/home/manipulateassetitem', model);
        const jsonResponse = await response.json();

        if (response.status != 200) {
            const queryError = jsonResponse as IQueryResultBase;
            throw new QueryException(queryError.error);
        }

        assetItems.forEach(ai => {
            ai.parent.removeChildren(ai);
            ai.parent = destination;
        });

        destination.addChildren(...assetItems);
    }

    public async uploadMapItem(projectId: number, navigationFolder: Navigation, newMapItem: ProjectMap, files?: FileList | Array<File>): Promise<ProjectMap> {
        if (isStreetMap(newMapItem)) {
            await this.addNewStreetMap(newMapItem, navigationFolder);
        }
        else if (isFileMap(newMapItem)) {
            let fileUploader: FileUploader = new FileUploader(this._httpClient);
            let uploadFiles: Array<ChunkedFile> = new Array<ChunkedFile>();

            for (let i = 0; i < files.length; i++) {
                const chunckedFile = fileUploader.addFile(files[i]);
                uploadFiles.push(chunckedFile);
            }

            const results = await fileUploader.uploadFilesAsync(
                this.onCreateFileMapAfterUploadCallbackFactory(
                    projectId,
                    navigationFolder,
                    newMapItem
                )
            );

            return results[0];
        }
        else {
            throw new Error(`Map of type ${newMapItem.type} is not supported`);
        }
    }

    public onCreateAssetFileAfterUploadCallbackFactory(projectId: number, assetParent: AssetDirectory): (chunk?: ChunkedFile) => Promise<AssetFile> {
        const onUploadedFile = async (result: CreateFileResult) => {
            let file = result.file;

            if (!file) {
                return null;
            }

            let assetItem = {
                name: file.nameWithoutExtension,
                parentId: assetParent.assetItemId,
                type: AssetItemType.File,
                fileId: file.fileId
            } as IAssetViewModel;

            let results = await this.addNewAssets<AssetFile>(
                projectId,
                [assetItem],
                assetParent
            );

            return results[0];
        };

        return this.onFileUploadedCallbackFactory(
            assetParent.file,
            onUploadedFile
        );
    }

    public onCreateImportAfterUploadCallbackFactory(parentDirectory: DriveFile): (chunk?: ChunkedFile) => Promise<Array<DriveFile>> {
        const onUploadedFile = async (result: CreateFileResult) => {
            let file = result.file;

            if (!file) {
                return null;
            }

            return await this.createImportAsync([file]);
        };

        return this.onFileUploadedCallbackFactory(
            parentDirectory,
            onUploadedFile
        );
    }

    public onCreateFileMapAfterUploadCallbackFactory(projectId: number, assetParent: AssetDirectory, newFileMap: FileMap): (chunk?: ChunkedFile) => Promise<FileMap> {
        const onUploadedFile = async (result: CreateFileResult) => {
            let file = result.file;

            if (!file) {
                return null;
            }

            let assetItem = {
                name: newFileMap.name,
                type: newFileMap.type,
                fileId: file.fileId,
                parentId: assetParent.assetItemId,
                isDefault: newFileMap.isDefault,
                location: newFileMap.location
            } as IAssetViewModel;

            let results = await this.addNewAssets<FileMap>(
                projectId,
                [assetItem],
                assetParent
            );

            return results[0];
        };

        return this.onFileUploadedCallbackFactory(
            assetParent.file,
            onUploadedFile
        );
    }

    private onFileUploadedCallbackFactory<T>(parentDirectory: DriveFile, onCreatedFile?: (createFileResult: CreateFileResult) => Promise<T>): (chunk?: ChunkedFile) => Promise<T> {
        const directoryGuid = parentDirectory.guid;

        return async (f) => {
            const result = await this._driveService.addFileAsync(directoryGuid, f);

            if (onCreatedFile) {
                return await onCreatedFile(result.createFileResult);
            }

            return null;
        };
    }

    public updateProjectLocation(model: any): Promise<Response> {
        return this._httpClient.postAsync('/project/home/updateprojectlocation', model);
    }

    public async getProjectsLocation(ids: Array<number>): Promise<Array<ProjectLocation>> {
        const response = await this._httpClient.getAsync(`/project/home/getprojectslocation?projectIds=${ids.join('&projectIds=')}`);
        const jsonResult = await response.json() as Array<ProjectLocation>;

        //jsonResult.forEach(l => l.location = JSON.parse(l.location));
        return jsonResult;
    }

    public async editProjectThumbnail(projectId: number, thumbnail: Blob): Promise<DriveFile> {
        let data = {
            projectId: projectId,
            thumbnail: thumbnail
        };

        const response = await this._httpClient.postFormDataAsync('/project/home/editprojectthumbnail', data);

        return await response.json();
    }

    public deleteThumbnail(projectId: number): Promise<Response> {
        return this._httpClient.postAsync('/project/home/deletethumbnail', projectId);
    }

    public async updateProjectAsync(source: Project, destination: Array<{ path: string; value: any; }>): Promise<void> {
        let patch: Array<Operation> = destination.map(
            m => ({ op: 'replace', path: m.path, value: m.value })
        );

        const response = await this._httpClient.patchAsJsonAsync(
            patch,
            null,
            String.Format(Routes.Api.Projects.Project, source.projectId)
        );

        if (response.status !== 204) {
            const queryResult: IQueryResultBase = await response.json();

            throw new QueryException(queryResult.error);
        }
    }

    public updateProjectPublicationStatus(model: { projectId: number; publicationStatus: PublicationStatus; }): Promise<Response> {
        return this._httpClient.putAsync('/project/home/updatepublicationstatus', model);
    }

    /**
     * @deprecated
     * Use updateItemAsync instead.
     */
    public updateItem(model: IUpdateProjectItemViewModel): Promise<Response> {
        return this._httpClient.putAsync('/project/home/updateitems', [model]);
    }

    /**
     * TODO Complete https://dev.azure.com/sbs-interactive/Vison/_workitems/edit/1271
     */
    public async updateItemAsync(model: IUpdateProjectItemViewModel): Promise<void> {
        const query = await this._httpClient.putAsync('/project/home/updateitems', [model]);

        if (!query.ok) {
            throw new QueryException({
                message: 'Unhandled error while updating',
                code: '',
                details: [],
                target: '',
                innerError: null
            });
        }
    }

    public updateItems(model: Array<IUpdateProjectItemViewModel>): Promise<Response> {
        return this._httpClient.putAsync('/project/home/updateitems', model);
    }

    public async deleteAsset(projectId: number, model: number): Promise<void> {
        const apiUri: string = String.Format(Routes.Api.Projects.Assets.Asset,
            projectId,
            model);

        const response: Response = await this._httpClient.deleteAsync(apiUri, model);

        await this._responseHandler.handleErrorsAsync(response);
    }

    public async deleteAssets(projectId: number, model: Array<number>): Promise<void> {
        const apiUri: string = String.Format(Routes.Api.Projects.Assets.Base,
            projectId,
            model);

        const response: Response = await this._httpClient.deleteAsync(apiUri, model);

        await this._responseHandler.handleErrorsAsync(response);
    }

    public async deleteProjectsAsync(projectIds: Array<number>): Promise<void> {
        let response = await this._httpClient.deleteAsync(
            Routes.Api.Projects.Base,
            projectIds
        );

        if (response.status == 400) {
            let jsonResponse = await response.json() as IQueryResultBase;
            throw new Error(jsonResponse.error.message);
        }
    }

    /**
     * Retourne l'ensemble de type de projet possibles.
     * La propriété children contient l'ensemble des templates
     * pour le type
     * */
    public async getProjectTypesAsync(): Promise<Array<AssetDirectory>> {
        const response = await this._httpClient.getAsync(Routes.Api.Projects.Templates.Base);
        const queryResult = await response.json();

        if (response.status !== 200) {
            throw new Error((queryResult as IQueryResultBase).error.message);
        }

        const projectTypes = queryResult as Array<AssetDirectory>;

        return AssetItem.jsonToArray<AssetDirectory>(projectTypes);
    }

    public async deleteProjectTypesAsync(typeIds: Array<number>): Promise<void> {
        const response = await this._httpClient.deleteAsync(
            Routes.Api.Projects.Types.Base,
            typeIds
        );

        if (response.status != 204) {
            const queryResult = await response.json() as IQueryResultBase;
            throw new QueryException(queryResult.error);
        }
    }

    public async deleteProjectTemplatesAsync(templateIds: Array<number>): Promise<void> {
        const response = await this._httpClient.deleteAsync(
            Routes.Api.Projects.Templates.Base,
            templateIds
        );

        if (response.status != 204) {
            const queryResult = await response.json() as IQueryResultBase;
            throw new QueryException(queryResult.error);
        }
    }

    public async updateProjectTemplateThumbnailAsync(templateId: number, picture: File): Promise<DriveFile> {
        const url = String.Format(Routes.Api.Projects.Templates.Thumbnail, templateId);
        const data = { file: picture };

        const response = await this._httpClient.postFormDataAsync(url, data);
        const queryResult = await response.json();

        if (response.status != 201) {
            throw new QueryException((queryResult as IQueryResultBase).error);
        }

        return queryResult as DriveFile;
    }

    public async deleteProjectTemplateThumbnailAsync(tempalteId: number): Promise<void> {
        const url = String.Format(Routes.Api.Projects.Templates.Thumbnail, tempalteId);
        const response = await this._httpClient.deleteAsync(url, null);

        if (response.status != 204) {
            const queryResult = await response.json() as IQueryResultBase;
            throw new QueryException(queryResult.error);
        }
    }

    public async updateProjectTemplateAsync(original: AssetTemplate, updated: IEditProjectTemplateViewModel): Promise<void> {
        updated = { ...original, ...updated };

        const patch = JsonPatch.compare(original, updated);
        const url = String.Format(
            Routes.Api.Projects.Templates.Template,
            original.assetItemId
        );

        const response = await this._httpClient.patchAsJsonAsync(patch, null, url);

        if (response.status != 204) {
            const queryError = await response.json() as IQueryResultBase;
            throw new QueryException(queryError.error);
        }

        JsonPatch.applyPatch(original, patch);
    }

    public async moveProjectTemplateAsync(templateId: number, typeId: number): Promise<void> {
        const patch: Array<Operation> = [
            { op: 'replace', path: '/parentId', value: typeId }
        ];

        const url = String.Format(Routes.Api.Projects.Templates.Template, templateId);

        const response = await this._httpClient.patchAsJsonAsync(patch, null, url);

        if (response.status != 204) {
            const queryError = await response.json() as IQueryResultBase;
            throw new QueryException(queryError.error);
        }
    }

    /**
     * Retourne l'ensemble des fichiers d'import de projet.
     * */
    public async getImportsAsync(): Promise<Array<DriveFile>> {
        const response = await this._httpClient.getAsync(Routes.Api.Projects.Imports.Base);
        const queryResult = await response.json();

        if (response.status === 401) {
            throw new UnauthorizedException();
        }
        else if (response.status !== 200) {
            throw new QueryException((queryResult as IQueryResultBase).error);
        }

        return queryResult as Array<DriveFile>;
    }

    /**
     * Retourne l'ensemble des fichiers d'import créé.
     * */
    public async createImportAsync(importFiles: Array<DriveFile>): Promise<Array<DriveFile>> {
        let data = importFiles.map(df => df.fileId);

        const response = await this._httpClient.postAsJsonAsync(data, null, Routes.Api.Projects.Imports.Base);
        const queryResult = await response.json();

        if (response.status !== 201) {
            throw new QueryException((queryResult as IQueryResultBase).error);
        }

        return queryResult as Array<DriveFile>;
    }

    public async getStandaloneEndpointUriAsync(
        apiUri: string,
        option: IDownloadStandaloneOption,
        baseUri?: string
    ): Promise<string> {
        const response = await this._httpClient.postAsync(apiUri, option, baseUri);

        return response.status == 201
            ? response.headers.get('location')
            : null;
    }

    public async changeProjectOwnerAsync(
        projectId: number,
        newOwnerId: number
    ): Promise<void> {
        const route = String.Format(Routes.Api.Projects.ChangeOwner, projectId);
        const response = await this._httpClient.postAsync(route, { newOwnerId });

        return await this._responseHandler.handleErrorsAsync(response);
    }

    public async getWaypointGraphAsync(projectId: number): Promise<WaypointGraph> {
        const route = String.Format(Routes.Api.Projects.Graph.WaypointGraph, projectId);

        const response = await this._httpClient.getAsync(
            route,
            null,
            null,
            new Headers({ 'Accept': 'application/xml, application/problem+json' }));

        await this._responseHandler.handleErrorsAsync(response);

        const content = await response.text();

        const parsed = gexf.parse<any, WaypointEdgeAttributes>(
            Graph,
            content,
            { respectInputGraphType: true });

        parsed.updateEachNodeAttributes((_, attributes: IGexfWaypointAttributesDto) => ({
            mapId: attributes.mapId,
            assetItems: attributes.assetItems,
            coordinates: {
                $type: 'cartesian',
                x: attributes.x,
                y: attributes.y,
                z: attributes.z
            }
        } as WaypointAttributes<ICartesianCoordinatesDto>));

        return parsed;
    }

    public async deleteWaypointAsync(projectId: number, waypointId: number): Promise<void> {
        const route = String.Format(
            Routes.Api.Projects.Graph.Waypoints.Waypoint.Base,
            projectId,
            waypointId);

        const response = await this._httpClient.deleteAsync(route, null);

        await this._responseHandler.handleErrorsAsync(response);
    }

    protected readonly _httpClient: HttpClient;

    public progressChanged: Function;
    public _driveService: DriveService;
    private readonly _responseHandler: IResponseHandler;
}
