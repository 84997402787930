import { AppContext } from 'Api/AppContext';
import { IError, IQueryResultBase } from 'Api/Dto/QueryResult';
import { AnalyticPermissions, AuditLogPermissions, DomainNameSystemPermissions, DrivePermissions, GroupPermissions, IdentityProviderPermissions, PlatformPermissions, ProjectPermissions, RolePermissions, SettingPermissions, SubscriptionPermissions, TagPermissions, UserPermissions, WorkerPermissions } from 'Api/Enums/Permissions';
import HttpClient from 'Api/HttpClient';
import { default as UserPermissionsDto } from 'Api/UserPermissions';
import VoSignInDialog, { ISignInDialogContext } from 'App/Areas/Shares/Dialogs/VoSignInDialog.vue';
import { injectTypes } from 'App/injectTypes';
import { lazyInject } from 'App/Inversify.config';
import ShowToastMixin from 'App/Mixins/ShowToastMixin';
import { IVisonUriService } from 'App/Services/UriServices/Core';
import { Component, Mixins, Prop, UIElement } from 'Framework/Components/UIElement';
import EnumHelper from 'Framework/Helpers/EnumHelper';
import { IDialogService } from 'Framework/Services/IDialogService';

enum ApplicationType { Web = 1, Standalone = 2, Both = Web | Standalone }

export interface IDrawerItem {
    icon: string;
    titleKey: string;
    uri: string;
    permissionFlags: Array<number> | number;
    permissionType?: keyof UserPermissionsDto;
    isSignInRequired: boolean;
    requiredPlugins?: Array<string>;
    availableIn: ApplicationType;
    children?: Array<IDrawerItem>;
}


@Component({
    name: 'x-drawer'
})
export default class SbsDrawer extends Mixins(UIElement, ShowToastMixin) {
    public mounted() {
        this.isMiniVariant = this._appContext.menuState == 3;
    }

    private async _onLogOffAsync(): Promise<void> {
        this._appContext.login = null;
        const root: any = this.$root as any;

        if (!root.isSpa) {
            const response = await this._httpClient.postFormDataAsync('account/logoff', null);

            if (response.redirected) {
                window.location.href = response.url;
            }

            if (response.status == 400) {
                const jsonResponse: IQueryResultBase = await response.json();
                let error: IError = jsonResponse.error;

                this.showToast(error.message, this.localizer('Close'), 'error', 8000);
            }
        }
    }

    private async _onOpenSignInDialogAsync(): Promise<void> {
        await this._dialogService.openAsync<VoSignInDialog, ISignInDialogContext>(
            'Shares',
            VoSignInDialog,
            {
                allowPasswordReset: false,
                allowRegister: false,
                loginProviders: []
            },
            {
                width: this._appContext.settings.dialogs.medium.width,
                height: this._appContext.settings.dialogs.medium.height,
                persistent: true
            }
        );
    }

    private _onClickLocale(): void {
        const currentUri = encodeURIComponent(`${window.location.pathname}${window.location.search}`);
        window.location.href = this.uriService.getAbsoluteUri(`${this._appContext.codeCulture}/locale?target=${currentUri}`);
    }

    private _accessIsAllowed(userPermissions: number, permissionFlags: Array<number> | number): boolean {
        if (permissionFlags) {
            if (permissionFlags instanceof Array) {
                let result = true;

                for (let permissionFlag of permissionFlags) {
                    result = result && EnumHelper.hasOneOfFlags(userPermissions, permissionFlag);
                }

                return result;
            }
            else {
                return EnumHelper.hasOneOfFlags(userPermissions, permissionFlags);
            }
        }

        return true;
    }

    public itemsSource(): Array<IDrawerItem> {
        const codeCulture = this._appContext.codeCulture;
        const administratorPortal = `${codeCulture}/administratorportal`;
        const profile = `${codeCulture}/profile`;

        const source: Array<IDrawerItem> = [
            {
                icon: 'fa-light fa-plane',
                titleKey: 'Views.Shared.Explore',
                uri: `/${codeCulture}/explore`,
                permissionFlags: ProjectPermissions.List,
                requiredPlugins: ['virtualTours'],
                permissionType: 'project',
                isSignInRequired: false,
                availableIn: ApplicationType.Web
            },
            {
                icon: 'fa-light fa-house',
                titleKey: 'Views.Shared.VideosHome',
                uri: `/${codeCulture}/video/explore`,
                permissionFlags: ProjectPermissions.List,
                requiredPlugins:['videos'],
                permissionType: 'project',
                isSignInRequired: false,
                availableIn: ApplicationType.Web
            },
            {
                icon: 'fa-light fa-plane',
                titleKey: 'Views.Shared.Explore',
                uri: `index.html`,
                permissionFlags: ProjectPermissions.List,
                permissionType: 'project',
                isSignInRequired: false,
                availableIn: ApplicationType.Standalone
            },
            {
                icon: 'fa-light fa-hard-drive',
                titleKey: 'Views.Shared.Drive',
                uri: `/${codeCulture}/drive`,
                permissionFlags: DrivePermissions.All,
                requiredPlugins: ['cms'],
                permissionType: 'drive',
                isSignInRequired: false,
                availableIn: ApplicationType.Web
            },
            {
                icon: 'fa-light fa-pen-ruler',
                titleKey: 'Views.Shared.Project',
                uri: `/${codeCulture}/project`,
                permissionFlags: [ProjectPermissions.List, ProjectPermissions.Edit],
                requiredPlugins: ['virtualTours'],
                permissionType: 'project',
                isSignInRequired: true,
                availableIn: ApplicationType.Web
            },
            {
                icon: 'fa-light fa-file-video',
                titleKey: 'Views.Shared.Videos',
                uri: `/${codeCulture}/video/home/list`,
                requiredPlugins: ['videos'],
                permissionFlags: [ProjectPermissions.List, ProjectPermissions.Edit],
                permissionType: 'project',
                isSignInRequired: true,
                availableIn: ApplicationType.Web
            },
            {
                icon: 'fa-light fa-gauge',
                titleKey: 'Views.Shared.Administration',
                uri: null,
                permissionFlags: null,
                permissionType: null,
                isSignInRequired: true,
                availableIn: ApplicationType.Web,
                children: [
                    {
                        icon: 'fa-light fa-server',
                        titleKey: 'Views.Shared.Platform',
                        uri: `/${administratorPortal}/platform`,
                        permissionFlags: [PlatformPermissions.List],
                        permissionType: 'platform',
                        isSignInRequired: true,
                        availableIn: ApplicationType.Web
                    },
                    {
                        icon: 'fa-light fa-address-book',
                        titleKey: 'Views.Shared.Subscription',
                        uri: `/${administratorPortal}/subscription`,
                        permissionFlags: SubscriptionPermissions.All,
                        permissionType: 'subscription',
                        isSignInRequired: true,
                        availableIn: ApplicationType.Web
                    },
                    {
                        icon: 'fa-light fa-lock',
                        titleKey: 'Views.Shared.Roles',
                        uri: `/${administratorPortal}/roles`,
                        permissionFlags: RolePermissions.All,
                        permissionType: 'role',
                        isSignInRequired: true,
                        availableIn: ApplicationType.Web
                    },
                    {
                        icon: 'fa-light fa-users',
                        titleKey: 'Views.Shared.Groups',
                        uri: `/${administratorPortal}/groups`,
                        permissionFlags: GroupPermissions.Create | GroupPermissions.Edit | GroupPermissions.Delete,
                        permissionType: 'group',
                        isSignInRequired: true,
                        availableIn: ApplicationType.Web
                    },
                    {
                        icon: 'fa-light fa-user',
                        titleKey: 'Views.Shared.Users',
                        uri: `/${administratorPortal}/users`,
                        permissionFlags: UserPermissions.Create | UserPermissions.Edit | UserPermissions.Delete,
                        permissionType: 'user',
                        isSignInRequired: true,
                        availableIn: ApplicationType.Web
                    },
                    {
                        icon: 'fa-light fa-id-card',
                        titleKey: 'Views.Shared.IdentityProviders',
                        uri: `/${administratorPortal}/identityproviders`,
                        permissionFlags: IdentityProviderPermissions.All,
                        permissionType: 'identityProvider',
                        isSignInRequired: true,
                        availableIn: ApplicationType.Web
                    },
                    {
                        icon: 'fa-light fa-globe',
                        titleKey: 'Views.Shared.DomainNameSystem',
                        uri: `/${administratorPortal}/domainnamesystem`,
                        permissionFlags: DomainNameSystemPermissions.All,
                        permissionType: 'domainNameSystem',
                        isSignInRequired: true,
                        availableIn: ApplicationType.Web
                    },
                    {
                        icon: 'fa-light fa-gears',
                        titleKey: 'Views.Shared.GeneralSettings',
                        uri: `/${administratorPortal}/generalsettings`,
                        permissionFlags: SettingPermissions.General,
                        permissionType: 'setting',
                        isSignInRequired: true,
                        availableIn: ApplicationType.Web
                    },
                    {
                        icon: 'fa-light fa-hard-drive',
                        titleKey: 'Views.Shared.DriveSettings',
                        uri: `/${administratorPortal}/drivesettings`,
                        permissionFlags: SettingPermissions.Drive,
                        permissionType: 'setting',
                        isSignInRequired: true,
                        availableIn: ApplicationType.Web
                    },
                    {
                        icon: 'fa-light fa-pen-ruler',
                        titleKey: 'Views.Shared.Projects',
                        uri: null,
                        permissionFlags: null,
                        isSignInRequired: true,
                        availableIn: ApplicationType.Web,
                        children: [
                            {
                                icon: 'fa-light fa-puzzle-piece',
                                titleKey: 'Views.Shared.Templates',
                                uri: `/${administratorPortal}/projects/templates`,
                                permissionFlags: ProjectPermissions.ListTemplate,
                                permissionType: 'project',
                                isSignInRequired: true,
                                availableIn: ApplicationType.Web
                            }
                        ]
                    },
                    {
                        icon: 'fa-light fa-industry',
                        titleKey: 'Views.Shared.Workers',
                        uri: null,
                        permissionFlags: null,
                        isSignInRequired: true,
                        availableIn: ApplicationType.Web,
                        children: [
                            {
                                icon: 'fa-light fa-ballot',
                                titleKey: 'Views.Shared.Tasks',
                                uri: `/${administratorPortal}/workers/tasks`,
                                permissionFlags: WorkerPermissions.List,
                                permissionType: 'worker',
                                isSignInRequired: true,
                                availableIn: ApplicationType.Web
                            },
                            {
                                icon: 'fa-light fa-industry',
                                titleKey: 'Views.Shared.Workers',
                                uri: `/${administratorPortal}/workers/workers`,
                                permissionFlags: WorkerPermissions.List | WorkerPermissions.Create | WorkerPermissions.Edit,
                                permissionType: 'worker',
                                isSignInRequired: true,
                                availableIn: ApplicationType.Web,
                            }
                        ]
                    },
                    {
                        icon: 'fa-light fa-tag',
                        titleKey: 'Views.Shared.Tags',
                        uri: `/${administratorPortal}/tags`,
                        permissionFlags: TagPermissions.Create | TagPermissions.Delete | TagPermissions.Update,
                        permissionType: 'tag',
                        isSignInRequired: true,
                        availableIn: ApplicationType.Web
                    },
                    {
                        icon: 'fa-light fa-key',
                        titleKey: 'Views.Shared.LoginSettings',
                        uri: `/${administratorPortal}/loginsettings`,
                        permissionFlags: SettingPermissions.Login,
                        permissionType: 'setting',
                        isSignInRequired: true,
                        availableIn: ApplicationType.Web
                    },
                    {
                        icon: 'fa-light fa-shield',
                        titleKey: 'Views.Shared.SecuritySettings',
                        uri: `/${administratorPortal}/securitysettings`,
                        permissionFlags: SettingPermissions.Security,
                        permissionType: 'setting',
                        isSignInRequired: true,
                        availableIn: ApplicationType.Web
                    },
                    {
                        icon: 'fa-light fa-book',
                        titleKey: 'Views.Shared.AuditLogs',
                        uri: `/${administratorPortal}/auditlogs`,
                        permissionFlags: AuditLogPermissions.List,
                        permissionType: 'auditLog',
                        isSignInRequired: true,
                        availableIn: ApplicationType.Web
                    },
                    {
                        icon: 'fa-light fa-envelope',
                        titleKey: 'Views.Shared.MailSettings',
                        uri: `/${administratorPortal}/mailsettings`,
                        permissionFlags: SettingPermissions.Mail,
                        permissionType: 'setting',
                        isSignInRequired: true,
                        availableIn: ApplicationType.Web
                    },
                    {
                        icon: 'fa-light fa-chart-mixed',
                        titleKey: 'Views.Shared.Statistics',
                        uri: null,
                        permissionFlags: AnalyticPermissions.All,
                        isSignInRequired: true,
                        availableIn: ApplicationType.Web,
                        children: [
                            {
                                icon: 'fa-light fa-chart-line',
                                titleKey: 'Views.Shared.Data',
                                //uri: `/${administratorPortal}/statistics/data`,
                                uri: '',
                                permissionFlags: [AnalyticPermissions.ShowLineDataIn | AnalyticPermissions.ShowLineDataOut],
                                permissionType: 'analytic',
                                isSignInRequired: true,
                                availableIn: ApplicationType.Web
                            },
                            {
                                icon: 'fa-light fa-chart-pie',
                                titleKey: 'Views.Shared.Storage',
                                //uri: `/${administratorPortal}/statistics/storage`,
                                uri: '',
                                permissionFlags: [AnalyticPermissions.ShowPieLogicalStorage | AnalyticPermissions.ShowPiePhysicalStorage],
                                permissionType: 'analytic',
                                isSignInRequired: true,
                                availableIn: ApplicationType.Web
                            },
                            {
                                icon: 'fa-light fa-file-chart-column',
                                titleKey: 'Views.Shared.Files',
                                //uri: `/${administratorPortal}/statistics/files`,
                                uri: '',
                                permissionFlags: [AnalyticPermissions.ShowLineLogicalFiles | AnalyticPermissions.ShowLinePhysicalFiles],
                                permissionType: 'analytic',
                                isSignInRequired: true,
                                availableIn: ApplicationType.Web
                            },
                            {
                                icon: 'fa-light fa-chart-line',
                                titleKey: 'Views.Shared.Requests',
                                uri: `/${administratorPortal}/statistics/requests`,
                                permissionFlags: [AnalyticPermissions.ShowLineRequest | AnalyticPermissions.ShowLineRequestError],
                                permissionType: 'analytic',
                                isSignInRequired: true,
                                availableIn: ApplicationType.Web
                            }
                        ]
                    }
                ],
            },
            {
                icon: 'fa-light fa-circle-user',
                titleKey: 'Views.Shared.MyAccount',
                uri: null,
                permissionFlags: null,
                isSignInRequired: true,
                availableIn: ApplicationType.Web,
                children: [
                    {
                        icon: 'fa-light fa-address-card',
                        titleKey: 'Views.Shared.PublicProfile',
                        uri: `/${profile}/public/index/${this._appContext.user.id}`,
                        permissionFlags: null,
                        isSignInRequired: true,
                        availableIn: ApplicationType.Web
                    },
                    {
                        icon: 'fa-light fa-user',
                        titleKey: 'Views.Shared.PersonalInformations',
                        uri: `/${profile}/profile`,
                        permissionFlags: null,
                        isSignInRequired: true,
                        availableIn: ApplicationType.Web
                    },
                    {
                        icon: 'fa-light fa-user-lock',
                        titleKey: 'Views.Shared.Authentification',
                        uri: `/${profile}/profile/account`,
                        permissionFlags: null,
                        isSignInRequired: true,
                        availableIn: ApplicationType.Web
                    },
                    {
                        icon: 'fa-light fa-key',
                        titleKey: 'Views.Shared.Tokens',
                        uri: `/${profile}/profile/tokens`,
                        permissionFlags: null,
                        isSignInRequired: true,
                        availableIn: ApplicationType.Web
                    },
                    {
                        icon: 'fa-light fa-wrench',
                        titleKey: 'Views.Shared.Preferences',
                        uri: `/${profile}/profile/preferences`,
                        permissionFlags: null,
                        isSignInRequired: true,
                        availableIn: ApplicationType.Web
                    },
                    {
                        icon: 'fa-light fa-envelope',
                        titleKey: 'Views.Shared.Email',
                        uri: `/${profile}/profile/email`,
                        permissionFlags: null,
                        isSignInRequired: true,
                        availableIn: ApplicationType.Web
                    },
                    {
                        icon: 'fa-light fa-flask',
                        titleKey: 'Views.Shared.ExperimentalFeatures',
                        uri: `/${profile}/profile/experimentalfeatures`,
                        permissionFlags: null,
                        isSignInRequired: true,
                        availableIn: ApplicationType.Web
                    },
                ]
            },
            {
                icon: 'fa-light fa-circle-info',
                titleKey: 'Views.Shared.About',
                uri: null,
                permissionFlags: null,
                isSignInRequired: false,
                availableIn: ApplicationType.Both,
                children: [
                    {
                        icon: 'fa-light fa-cookie',
                        titleKey: 'Views.Shared.Cookies',
                        uri: `/${codeCulture}/public/about/cookies`,
                        permissionFlags: null,
                        isSignInRequired: false,
                        availableIn: ApplicationType.Web
                    },
                    {
                        icon: 'fa-light fa-file-signature',
                        titleKey: 'Views.Shared.TermsAndConditions',
                        uri: `/${codeCulture}/public/about/termsandconditions`,
                        permissionFlags: null,
                        isSignInRequired: false,
                        availableIn: ApplicationType.Web
                    },
                    {
                        icon: 'fa-light fa-box-open-full',
                        titleKey: 'Views.Shared.Libraries',
                        uri: `/${codeCulture}/public/about/libraries`,
                        permissionFlags: null,
                        isSignInRequired: false,
                        availableIn: ApplicationType.Web
                    },
                    {
                        icon: 'fa-light fa-file-shield',
                        titleKey: 'Views.Shared.PrivacyPolicy',
                        uri: 'https://www.sbs-interactive.fr/Politique-de-confidentialité',
                        permissionFlags: null,
                        isSignInRequired: false,
                        availableIn: ApplicationType.Both
                    },
                ]
            }
        ];

        const filterItem = (item: IDrawerItem) => {
            const isAvailableMenuItem = EnumHelper.hasOneOfFlags(
                item.availableIn,
                this._appContext.isStandalone
                    ? ApplicationType.Standalone
                    : ApplicationType.Web
            );

            if (!isAvailableMenuItem
                || (item.isSignInRequired
                    && !this._appContext.user.isSignedIn)) {
                return false;
            }

            if (item.requiredPlugins) {
                if (!item.requiredPlugins.every(plugin => this._appContext.subscriptionPlugins[plugin]?.isEnabled == true)) {
                    return false;
                }
            }

            if (item.permissionType) {
                let userPermission = this._appContext.user.permissions[item.permissionType];

                if (userPermission != null) {
                    return this._accessIsAllowed(userPermission, item.permissionFlags);
                }
            }

            if (item.children) {
                item.children = item.children.filter(filterItem);
                return item.children.length > 0;
            }

            return true;
        };

        return source.filter(filterItem);
    }

    public isMiniVariant: boolean = false;

    @Prop({ type: Boolean, required: false, default: true })
    public isOpened: boolean;

    @lazyInject(AppContext)
    private _appContext: AppContext;

    @lazyInject(HttpClient)
    private _httpClient: HttpClient;

    @lazyInject(injectTypes.IVisonUriService)
    public uriService: IVisonUriService;

    @lazyInject(injectTypes.IDialogService)
    private readonly _dialogService: IDialogService;
}
