export async function delayAsync(timeout: number): Promise<void> {
    return new Promise(resolve => {
        setTimeout(resolve, timeout);
    });
}

const isFulfilled = <T>(input: PromiseSettledResult<T>): input is PromiseFulfilledResult<T> =>
    input.status === 'fulfilled'

export type SettledResult<TResult, TReason = any> = {
    values: Array<TResult>,
    reasons: Array<TReason>
}

export function partitionPromiseResults<TResult, TReason = any>(
    results: Array<PromiseSettledResult<TResult>>
): SettledResult<TResult, TReason> {
    const result: SettledResult<TResult, TReason> = {
        values: [],
        reasons: []
    };

    return results.reduce((acc, r) => {
        if (isFulfilled(r)) {
            acc.values.push(r.value);
        }
        else {
            acc.reasons.push(r.reason);
        }
        return acc;
    }, result);
}

export class CancellationTokenSource {
    public constructor() {
        this.token = new CancellationToken(this);
        this._isCancellationRequested = false;
    }

    public cancel(): void {
        this._isCancellationRequested = true;
    }

    public get isCancellationRequested(): boolean {
        return this._isCancellationRequested;
    }

    public readonly token: CancellationToken;

    private _isCancellationRequested: boolean;
}

export class CancellationToken {
    public constructor(source: CancellationTokenSource) {
        this._source = source;
    }

    public get isCancellationRequested(): boolean {
        return this._source.isCancellationRequested;
    }

    private readonly _source: CancellationTokenSource;
}

export type EventHandler<T = void> = T extends void ? (sender: any) => void : (sender: any, eventArgs: T) => void;

export interface IEvent<T = void> {
    subscribe(handler: EventHandler<T>): void;
    unsubscribe(handler: EventHandler<T>): void;
}

export class EventDispatcher<T = void> implements IEvent<T> {
    public subscribe(handler: EventHandler<T>): void {
        this._handlers.push(handler);
    }

    public unsubscribe(handler: EventHandler<T>): void {
        this._handlers = this._handlers.filter(h => h != handler);
    }

    public dispatch(sender: any, value: T): void {
        this._handlers.forEach(h => h(sender, value))
    }

    public toEvent(): IEvent<T> {
        return this as IEvent<T>;
    }

    private _handlers: Array<EventHandler<T>> = [];
}

export function getOrAdd<TKey extends string | number | symbol, TValue>(
    dictionary: Record<TKey, TValue>,
    key: TKey,
    valueFactory: (key: TKey) => TValue
): TValue {
    if (dictionary.hasOwnProperty(key)) {
        return dictionary[key];
    }

    const value = valueFactory(key);
    dictionary[key] = value;

    return value;
}
